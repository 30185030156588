<template>
  <custom-header class="" isfooter>
    <template v-slot:header-main>
      <div class="flex justify-end mr-4">
        <header-element class="relative mr-4">
          <btn
            class=""
            @editPoll="editPoll"
            emitName="editPoll"
            buttonText="Edit"
            bold
            color="theme"
          />
        </header-element>
        <header-element class="relative">
          <btn
            class=""
            @pushStage="pushStage"
            emitName="pushStage"
            buttonText="Push to stage"
            bold
            fill
          />
        </header-element>
      </div>
    </template>
  </custom-header>
</template>

<script>
import CustomHeader from "../../layout/custom-header.vue";
import HeaderElement from "../../layout/header-element.vue";
import Btn from "../../ui/buttons/btn.vue";
export default {
  components: {
    CustomHeader,
    HeaderElement,
    Btn,
  },
  methods: {
    editPoll() {
      this.$emit("editPoll");
    },
    pushStage() {
      this.$emit("pushStage");
    },
  },
};
</script>

<style>
</style>