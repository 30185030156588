import { format, formatDistanceStrict, formatRelative, subDays, add, endOfDay, isBefore, differenceInCalendarDays  } from 'date-fns'

const getDateString = (date) => {
  const now = new Date()
  // const diff = differenceInDays(now, date.toDate())
  return formatDistanceStrict(date.toDate(), now, { addSuffix: true })
}

const isToday = (date) => {
  return differenceInCalendarDays(new Date(), date.toDate())
}

const countries = [
  'ALL',
  'GB',
  'US',
]

const topics = [
  'All Categories',
  'People in the News',
  'Finance & Economy',
  'Food & Drink',
  'Life & Culture',
  'Parenting',
  'Politics',
  'Relationships',
  'Sport',
  'Technology',
  'TV & Film',
  'Work life',
]

const timeLimits = [
  {
    text: "No time limit",
    val: false
  },
  {
    text: "1 day",
    val: 1
  },
  {
    text: "2 days",
    val: 2
  },
  {
    text: "3 days",
    val: 3
  },
  {
    text: "4 days",
    val: 4
  },
  {
    text: "5 days",
    val: 5
  },
  {
    text: "6 days",
    val: 6
  },
  {
    text: "1 week",
    val: 7
  }
]

const getDeadlineTime = (days) => {
  const now = new Date()
  let end = endOfDay(add(new Date(), {days: days}))
  let res = {
    deadline: end
  }
  days == 0 ? res['timeLimit'] = false : res['timeLimit'] = true
  console.log(res)
  return res
}

const getVoteStatus = (timeLimit, deadline) => {
  if (timeLimit == 0){
    return ""
  } else {
    let now = new Date()
    let end = deadline.toDate()
    // console.log(end)
    // console.log(now)
    if (isBefore(end, now)) {
      return "Poll now closed"
    } else {
      // console.log("poll closes in...")
      return "Poll closes in " + formatDistanceStrict(end, now,{ addSuffix: true } )
    }
  }
}

const colorMap = {
  'bg-positive': 'bg-positive',
  'bg-positivefaded': 'bg-positivefaded',
  'bg-negative': 'bg-negative',
  'bg-negativefaded': 'bg-negativefaded',
  'bg-theme': 'bg-theme',
  'bg-themefaded': 'bg-themefaded',
  'bg-default': 'bg-default',
  'bg-defaultfaded': 'bg-defaultfaded',
  'bg-amber': 'bg-amber',
  'bg-amberfaded': 'bg-amberfaded',
  'bg-amberred': 'bg-amberred',
  'bg-amberredfaded': 'bg-amberredfaded',
  'bg-neutral': 'bg-neutral',
  'bg-neutralfaded': 'bg-neutralfaded',
  'bg-buttondisabled': 'bg-buttondisabled',
  'bg-buttonpass': 'bg-buttonpass',
  'bg-buttongray': 'bg-buttongray',
  'bg-offblack': 'bg-offblack',
  'bg-offblackfaded': 'bg-offblackfaded',
  'bg-white': 'bg-white',
  'text-buttondisabled': 'text-buttondisabled',
  'text-buttongray': 'text-buttongray',
  'text-positivefaded': 'text-positivefaded',
  'text-negativefaded': 'text-negativefaded',
  'text-buttonpass': 'text-buttonpass',
  'text-positive' : 'text-positive',
  'text-negative' : 'text-negative',
  'text-default' : 'text-default',
  'text-amber' : 'text-amber',
  'text-amberred' : 'text-amberred',
  'border-positive' : 'border-positive',
  'border-negative' : 'border-negative',
  'border-default' : 'border-default',
  'border-amber': 'border-amber',
  'border-amberred': 'border-amberred',
  'border-positivefaded' : 'border-positivefaded',
  'border-negativefaded' : 'border-negativefaded',
  'border-defaultfaded' : 'border-defaultfaded',
  'border-amberfaded': 'border-amberfaded',
  'border-amberredfaded': 'border-amberredfaded',
  'border-buttondisabled': 'border-buttondisabled',
}

const getColor = (element, color, faded=false) => {
  if (faded) {
    return colorMap[element+ "-" + color+'faded']
  } else {
    return colorMap[element+ "-" + color]
  }
}

export {
  getDateString,
  isToday,
  topics,
  countries,
  timeLimits,
  getDeadlineTime,
  getVoteStatus,
  getColor
}