import { reactive } from '@vue/reactivity';
import { db, uid, uploadImg, votedLog, pollsLive, increment, arrayUnion } from '../firebase';
import { collection, addDoc } from "firebase/firestore";
import {  uploadPoll as uploadNewPoll } from './new-poll';

const option0 = {
  index: 0,
  type: 'theme',
  blob: null,
  imgURL: null,
  text: null,
}

const option1 = {
  index: 1,
  type: 'theme',
  blob: null,
  imgURL: null,
  text: null,
}

let options = reactive([option0, option1])

let binaryImagePoll = reactive({
  options: options,
  type: "binary-image",
  status: "preview",
})

const updateImg = (index, blob) => {
  console.log(index, blob)
  options[index].blob = blob
  options[index].imgURL = URL.createObjectURL(blob);
}

const updateOptionText = (index, text) => {
  options[index].text = text
}

const uploadPoll = async () => {
  const docRef = await addDoc(collection(db, "polls-stage"), {});
  let imgURLs = []
  await uploadImg(docRef.id + "_0", binaryImagePoll.options[0].blob).then(res => {
    imgURLs.push(res)
  })
  await uploadImg(docRef.id + "_1", binaryImagePoll.options[1].blob).then(res => {
    imgURLs.push(res)
  })
  delete binaryImagePoll.options[0]['blob']
  binaryImagePoll.options[0]['imgURL'] = imgURLs[0] 
  delete binaryImagePoll.options[1]['blob'] 
  binaryImagePoll.options[1]['imgURL'] = imgURLs[1] 

  uploadNewPoll(binaryImagePoll, docRef.id)
}

export {
  binaryImagePoll,
  options,
  updateImg,
  updateOptionText,
  uploadPoll,
}