import { reactive, ref } from '@vue/reactivity';
import { db, uid, uploadImg, USERS_REF, pollsLive, increment, decrement, arrayUnion,  } from './firebase';
import { Timestamp, collection, addDoc, setDoc, doc, updateDoc } from "firebase/firestore";
import { comment } from 'postcss';

let newCommentText = ref('')

const updateNewComment = (val) => {
  newCommentText = val
}

const  postComment = async (pollId, comment) => {
  let newComment = {
    pollId: pollId,
    uid: uid,
    comment: comment,
    date: Timestamp.fromDate(new Date()),
    upvotes: 0,
    upvoteIds: [],
    downvotes: 0,
    donwvoteIds: [],
    votes: 0
  }
  console.log(newComment)
  let refPath = "polls-live/" + pollId +"/comments"
  const docRef = await addDoc(collection(db, refPath), {});
  console.log(docRef.id)

  try {
    setDoc(doc(db, refPath, docRef.id), {
      ...newComment
    });
    console.log("Document written with ID: ", docRef.id);  
    updateDoc(db.collection('polls-live').doc(pollId), {
      commentCount: increment,
    })
    return true
  } catch (error){
    console.error(error)
    return false
  }
}

const fetchComments = async (pollId, orderBy="date") => {
  const commentsRef = db.collection('polls-live/'+pollId+"/comments").orderBy(orderBy, "desc")
  let comments = []
  // console.log("fetching comments...")
  await commentsRef.get().then((querySnapshot) => {
    // console.log(querySnapshot)
    querySnapshot.forEach((doc) => {
      let comment = doc.data()
      comment['id'] = doc.id
      comments.push(comment)
    })
  })
  return comments
}

const upvoteComment = async (pollId, commentId) => {
  let batch = db.batch()
  const refPath = "polls-live/" + pollId +"/comments"
  let ref = db.collection(refPath)
  let doc = ref.doc(commentId)
  return updateDoc(doc, {
    upvotes: increment,
    upvoteIds: arrayUnion(uid),
    votes: increment
  })
}

const downvoteComment = async (pollId, commentId) => {
  let batch = db.batch()
  const refPath = "polls-live/" + pollId +"/comments"
  let ref = db.collection(refPath)
  let doc = ref.doc(commentId)
  return updateDoc(doc, {
    downvotes: increment,
    downvoteIds: arrayUnion(uid),
    votes: increment
  })
}

export {
  updateNewComment,
  fetchComments,
  postComment,
  newCommentText,
  upvoteComment,
  downvoteComment
}