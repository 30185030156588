<template>
  <div class="max-w-sm bg-white shadow-md rounded px-8 py-4 mx-auto mt-8">
    <input-base
      label="Email"
      full
      placeholdertext="email"
      emitName="enterEmail"
      @enterEmail="enterEmail"
    />
    <input-base
      label="Password"
      full
      placeholdertext="password"
      emitName="enterPassword"
      @enterPassword="enterPassword"
    />
    <btn
      class=""
      @handleSignIn="handleSignIn"
      emitName="handleSignIn"
      buttonText="Sign In"
      bold
      fill
      color="theme"
    />
  </div>
</template>

<script>
import Btn from "../components/ui/buttons/btn.vue";
import InputBase from "../components/ui/inputs/input-base.vue";
import { signIn } from "../db/firebase";
import { reactive } from "vue";
export default {
  components: {
    InputBase,
    Btn,
  },
  setup() {
    const state = reactive({
      email: 0,
      pwd: null,
    });
    const enterEmail = (email) => {
      state.email = email;
    };
    const enterPassword = (pwd) => {
      state.pwd = pwd;
    };
    const handleSignIn = () => {
      console.log("sign in", state.email);
      signIn(state.email, state.pwd);
    };
    return {
      enterEmail,
      enterPassword,
      handleSignIn,
    };
  },
};
</script>

<style>
</style>