<template>
  <div class="relative z-0">
    <svg
      :width="getSVGSize()"
      :height="getSVGSize()"
      class="pie-container z-0 rounded-full"
    >
      <circle
        :fill="getFillColor()"
        :r="circleCentre()"
        :cx="circleCentre()"
        :cy="circleCentre()"
      />
      <circle
        id="p12"
        :r="circleRadius()"
        :cx="circleCentre()"
        :cy="circleCentre()"
        stroke="#ddd"
        :stroke-width="circleCentre()"
        fill="none"
        :stroke-dasharray="getStrokeArray()"
        stroke-dashoffset="0"
      />
    </svg>
    <div class="absolute inner-position">
      <div
        class="
          bg-gray-50
          rounded-full
          flex
          justify-center
          items-center
          text-3xl
        "
        :class="getInnerSize()"
      ></div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_TIME } from "../../../db/play";
export default {
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: null,
    },
    topCount: {
      type: Number,
      default: 0,
    },
    totalVotes: {
      type: Number,
      default: 0,
    },
    avgScore: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: "theme",
    },
  },
  data() {
    return {
      size: "48  ",
    };
  },
  methods: {
    getSVGSize() {
      return this.small ? "48 " : "200";
      // return "64";
    },
    circleCentre() {
      return this.getSVGSize() / 2;
    },
    circleRadius() {
      return this.getSVGSize() / 4;
    },
    getInnerPosition() {
      return this.small ? "top-0 left-0" : "top-6 left-6";
    },
    getInnerSize() {
      return this.small ? "w-8 h-8" : "w-32 h-32";
    },
    getInnerTextClass() {
      let res1;
      this.small ? (res1 = "font-bold") : (res1 = "text-3xl");
      let res2 = this.type;
      return "text-" + res2 + " " + res1;
    },
    getStrokeArray() {
      let size;
      if (this.small) {
        size = 12;
      } else {
        console.log("large");
        size = 32;
      }
      let res = 2 * 3.14 * size;
      // res = res * (this.topCount / this.totalVotes);
      res = res * ((DEFAULT_TIME - this.avgScore) / DEFAULT_TIME);
      // res = 157 / 2;
      res = res.toString();
      // console.log(item.count / this.totalVotes);
      // return res + " 314";
      return res + " 314";
    },
    getPercent() {
      // console.log(this.segment);
      // const top = this.segment;
      // let res = Math.round((100 * this.topCount) / this.totalVotes);
      // return res.toString() + "%";
    },
    getFillColor(type) {
      const colors = {
        theme: "#00c7e6",
        amber: "#ffd500",
        amberred: "#fa9f3e",
        negative: "#ff4240",
      };
      return colors[this.color];
    },
  },
};
</script>

<style>
.pie-container {
  transform: rotate(-0.25turn);
}
@keyframes animate_p1 {
  to {
    /* stroke-dasharray: 78.5 314; */
  } /* 314 ÷ 4 = 78.5 */
}
#bg {
  fill: #00c7e6;
}
#p1 {
  stroke-dasharray: 0 314; /* 2π × 50 ≈ 314 */
  stroke-dashoffset: 0;
  animation: animate_p1 1s linear forwards;
}
.inner-position {
  top: 8px;
  left: 8px;
}
</style>