<template>
  <div class="">
    <div v-if="state.loading" class="flex justify-center mt-16">
      <loading />
    </div>
    <div v-else class="">
      <div class="h-full">
        <div v-if="!showResult">
          <div class="flex w-full justify-items-stretch mb-4 mt-2">
            <div
              v-for="item in state.pollObj.options"
              :key="item.index"
              class="
                w-24
                text-sm
                cursor-pointer
                py-2
                px-1
                text-center
                rounded
                shadow
                button-ripple-rounded
                flex
                items-center
                justify-center
              "
              :class="getClasses(item)"
              @click="selectOption(item.index)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <div v-else class="flex w-full mb-">
          <div class="flex w-full">
            <result-column
              v-for="option in state.pollObj.options"
              :key="option.index"
              :text="option.text"
              :count="getResultCount(option.index)"
              :totalVotes="state.pollObj.totalVotes"
              :isTopResult="option.index == getTopResult()"
              :type="option.type"
              class="mb-"
            />
          </div>
        </div>
        <div class="flex">
          <img
            v-if="poll.imgURL"
            class="w-full h-full object-cover"
            :src="poll.imgURL"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import { uploadPoll, options } from "../../../db/new-poll/scale";
import { db, submitVote } from "../../../db/firebase";
import Loading from "../../ui/loading.vue";
import ResultColumn from "../../poll/results/result-column.vue";
import { doc, onSnapshot } from "firebase/firestore";
import { getColor } from "../../../db/utils";
export default {
  props: ["poll", "showResult", "pollId"],
  components: {
    Loading,
    ResultColumn,
  },
  setup(props) {
    const state = reactive({
      loading: false,
      pollObj: props.poll,
      pollId: props.pollId,
    });

    const route = useRoute();
    let pollId;
    if (state.pollId) {
      pollId = state.pollId;
      const unsub = onSnapshot(doc(db, "polls-live", pollId), (doc) => {
        state.pollObj = doc.data();
      });
    } else if (route.name == "PollFeedDetail") {
      pollId = route.params.pollId;
      const unsub = onSnapshot(
        doc(db, "polls-live", route.params.pollId),
        (doc) => {
          state.pollObj = doc.data();
        }
      );
    }

    const getClasses = (item) => {
      let classes = getColor("bg", item.type);

      if (item.type == "white") {
        classes = classes + " text-gray-500";
      } else {
        classes = classes + " text-white";
      }
      if (item.index != 4) {
        classes = classes + " mr-2";
      }
      return classes;
    };

    const selectOption = async (option) => {
      // console.log("option: ", option);
      if (props.showResult) {
        return;
      }
      state.loading = true;
      // console.log("select option: ", option);
      submitVote(pollId, option).then(() => {
        // console.log("submitted");
      });
    };
    const getResultCount = (index) => {
      return state.pollObj["count_" + index];
    };

    const getTopResult = (index) => {
      let indexTop = 0;
      let countTop = state.pollObj["count_0"];
      state.pollObj.options.forEach((item, index) => {
        if (state.pollObj["count_" + item.index] > countTop) {
          indexTop = item.index;
          countTop = state.pollObj["count_" + item.index];
        }
      });
      return indexTop;
    };

    const avgScore = () => {
      let sum = 0;
      for (let i = 0; i < 10; i++) {
        sum = sum + (i + 0) * state.pollObj["count_" + i];
      }
      console.log(sum);
      return (sum / state.pollObj.totalVotes).toFixed(1);
    };

    return {
      state,
      options,
      getClasses,
      selectOption,
      getResultCount,
      getTopResult,
      avgScore,
    };
  },
  methods: {
    publishPoll() {
      console.log("publish");
      uploadPoll();
    },
    editPoll() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>