<template>
  <div class="pt-">
    <div class="text-xs pb-1 font-medium text-gray-600 mb-2">Options</div>
    <option-default
      v-for="item in options"
      class=""
      :key="item.index"
      :optionIndex="item.index"
      pollType="standard"
    />
    <btn
      @addOption="addOption"
      emitName="addOption"
      buttonText="+ Add option"
      bold
      class="-mt-3"
    />
  </div>
</template>

<script>
// import AddThumbnail from "../ui/inputs/add-thumbnail.vue";
import Btn from "../ui/buttons/btn.vue";
import OptionDefault from "./option-default.vue";
// import DropdownMenu from "../components/ui/dropdown/dropdown-menu.vue";
import {
  // updateTitle,
  // updateInfo,
  updateImg,
  updateTopic,
  updateTimeLimit,
  options,
  addOption,
} from "../../db/new-poll/standard";
export default {
  components: {
    // InputBase,
    Btn,
    OptionDefault,
    // DropdownMenu
  },
  setup() {
    const updatePollImg = (imgURL, blob) => {
      updateImg(imgURL, blob);
    };

    return {
      options,
      addOption,
      updatePollImg,
    };
  },
};
</script>

<style>
</style>