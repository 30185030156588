<template>
  <router-link :to="getRouterLink()" class="w-full">
    <div class="hover:bg-gray-50 px-4 py-4 cursor-pointer flex">
      <div class="">
        <img-thumb v-if="img" :imgurl="img" />
      </div>
      <div class="flex-1">
        <div class="flex-col justify-between">
          <div class="">
            <div class="flex justify-between mb-">
              <div class="flex">
                <item-info :info="convertDate()" />
              </div>
              <div class="flex">
                <!-- <div class="text-xxs text-theme font-semibold uppercase ml-2">
                  {{ topic }}
                </div> -->
              </div>
            </div>
            <item-title class="w-full" :title="title" />
          </div>
        </div>
        <div class="flex mb-">
          <item-info class="font" :info="getVoteString()" />
          <!-- <item-info class="mx-2" info="&#8226;" /> -->
          <item-info class="font" :info="getVoteStatusString()" />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ItemTitle from "../poll-item/item-title.vue";
import ItemInfo from "../poll-item/item-info.vue";
import { getDateString, getVoteStatus } from "../../../db/utils";
import { getShowResult } from "../../../db/firebase";
import { reactive, ref } from "vue";
import ImgThumb from "../../ui/img/img-thumb.vue";
import ImgAvatar from "../../ui/img/img-avatar.vue";
export default {
  props: [
    "title",
    "topic",
    "date",
    "status",
    "timeLimit",
    "deadline",
    "totalVotes",
    "pollId",
    "img",
  ],
  components: { ItemTitle, ItemInfo, ImgThumb },
  methods: {
    convertDate() {
      return getDateString(this.date);
    },
    getVoteString() {
      return this.totalVotes + " votes";
    },

    getVoteStatusString() {
      if (this.status != "live") {
        return "<not live>";
      }
      return getVoteStatus(this.timeLimit, this.deadline);
    },
    getRouterLink() {
      if (this.$route.path == "/stage") {
        console.log("stage", `/stage/${this.pollId}`);
        return `/stage/${this.pollId}`;
      } else {
        return `/${this.pollId}`;
      }
    },
  },
};
</script>

<style>
</style>