<template>
  <div class="flex mb-4 mt-1 ml-1 relative">
    <btn
      class="w-full z-0"
      :class="getClass()"
      @selectOption="selectOption"
      emitName="selectOption"
      :buttonText="option.text"
      bold
      :fill="option.imgURL || option.optionImg ? false : true"
      :outlined="option.imgURL || option.optionImg ? true : false"
      full
      :color="option.type"
    />
    <div
      class="w-16 h-16 absolute left-0 rounded-full bg-gray-300 overflow-hidden"
      v-if="option.imgURL"
    >
      <img class="w-16 h-16 object-cover" :src="option.imgURL" />
    </div>
    <div
      class="w-16 h-16 rounded-full bg-gray-300 overflow-hidden absolute left-0"
      v-if="option.optionImg"
    >
      <img class="w-16 h-16 object-cover" :src="option.optionImg" />
    </div>
  </div>
</template>

<script>
import Btn from "../../ui/buttons/btn.vue";
export default {
  props: ["option", "live"],
  components: {
    Btn,
  },
  methods: {
    selectOption() {
      this.$emit("selectOption", this.option);
    },
    getClass() {
      if (this.option.imgURL || this.option.optionImg) {
        return "mt-4 ml-10";
      }
    },
  },
};
</script>

<style>
</style>