<template>
  <div class="pt-">
    <div class="text-xs pb-1 font-medium text-gray-600 mb-2">Options</div>
    <option-default
      v-for="item in options"
      class=""
      :key="item.index"
      :optionIndex="item.index"
      fixed
      pollType="binary-text"
    />
  </div>
</template>

<script>
import OptionDefault from "./option-default.vue";
import { options } from "../../db/new-poll/binary-text";

export default {
  components: {
    OptionDefault,
  },
  setup() {
    return {
      options,
    };
  },
};
</script>

<style>
</style>