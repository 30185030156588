<template>
  <div>
    <div v-if="label != ''" class="text-xs pb-1 font-medium text-gray-600">
      {{ label
      }}<span v-if="optional" class="ml-2 font-normal text-gray-400"
        >optional</span
      >
    </div>
    <input
      v-if="!textbox"
      :placeholder="placeholdertext"
      class="
        border border-gray-400
        px-3
        py-2
        focus:outline-none focus:border-4 focus:border-default
        active:outline-none active:border-4 active:border-default
        text-sm
        mb-6
      "
      :class="getClasses()"
      @keyup="handleChange"
    />
    <textarea
      v-if="textbox"
      :placeholder="placeholdertext"
      :rows="getTextBoxRows"
      class="
        border border-gray-400
        px-3
        py-2
        focus:outline-none focus:border-4 focus:border-default
        active:outline-none active:border-4 active:border-default
        text-sm
        mb-6
      "
      :class="getClasses()"
      @keyup="handleChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    padded: Boolean,
    full: Boolean,
    placeholdertext: String,
    textbox: Boolean,
    optional: Boolean,
    flatright: Boolean,
    flatleft: Boolean,
    emitName: String,
    small: Boolean,
  },
  methods: {
    getClasses() {
      let classesString = [];
      if (this.padded) {
        classesString.push("mx-2 my-4");
      }
      if (this.full) {
        classesString.push("w-full");
      }
      if (this.flatright && !this.flatleft) {
        classesString.push("rounded-l-md border-r-0");
      }
      if (!this.flatright && this.flatleft) {
        classesString.push("rounded-r-md ");
      }
      if (!this.flatright && !this.flatleft) {
        classesString.push("rounded-md");
      }
      return classesString.join(" ");
    },
    getTextBoxRows() {
      if (this.small) {
        return 2;
      }
      return 4;
    },
    handleChange(event) {
      this.$emit(this.emitName, event.target.value);
    },
  },
};
</script>

<style>
</style>