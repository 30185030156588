<template>
  <div class="">
    <poll-item-header
      v-if="state.pollObj"
      :title="state.pollObj.title"
      :topic="state.pollObj.topic"
      :date="state.pollObj.date"
      :status="state.pollObj.status"
      :timeLimit="state.pollObj.timeLimit"
      :deadline="state.pollObj.deadline"
      :totalVotes="state.pollObj.totalVotes"
      :pollId="pollId"
      :img="state.pollObj.thumbURL"
    />
    <div v-if="state.pollObj" class="flex-col justify-between mt-">
      <div v-if="showResult()" class="px-6 pt-4 border-t border-gray-200 pb-4">
        <result-bar
          v-for="option in state.pollObj.options"
          :key="option.index"
          :text="option.text"
          :count="getResultCount(option.index)"
          :totalVotes="state.pollObj.totalVotes"
          :isTopResult="option.index == getTopResult()"
          :type="option.type"
          :id="pollId"
          class="mb-3"
          :img="option.optionImg"
        />
        <thread-link
          v-if="state.pollObj.thread"
          :threadId="state.pollObj.thread"
        />
      </div>
      <!-- <div class="flex" v-if="state.pollObj && !showResult()">
        <remove-item />
      </div> -->
      <poll-item-footer
        v-if="state.pollObj && showResult()"
        :pollId="pollId"
        :status="state.pollObj.status"
        :timeLimit="state.pollObj.timeLimit"
        :deadline="state.pollObj.deadline"
        :totalVotes="state.pollObj.totalVotes"
        :commentCount="state.pollObj.commentCount"
        class="hover:bg-gray-"
      />
      <div
        class="flex justify-between items-center py-1 px-3"
        v-if="state.pollObj && !showResult()"
      >
        <div class="">
          <btn
            icon="trash"
            iconLabel="Not interested"
            :iconFirst="true"
            emitName="removePoll"
            @removePoll="removePoll"
            color="buttonpass"
          />
        </div>
        <router-link :to="'/' + pollId">
          <div
            class="
              border border-1 border-theme
              rounded-full
              text-xs text-theme
              px-3
              py-1
              cursor-pointer
              font-bold
              hover:bg-gray-50
            "
          >
            Vote
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ResultBar from "../results/result-bar.vue";
import PollItemHeader from "../poll-item/poll-item-header.vue";
import PollItemFooter from "../poll-item/poll-item-footer.vue";
import ThreadLink from "../poll-item/thread-link.vue";
import RemoveItem from "../poll-item/remove-item.vue";
import { getShowResult } from "../../../db/firebase";
import { reactive } from "vue";
import { db, submitPass } from "../../../db/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import Btn from "../../ui/buttons/btn.vue";
export default {
  props: ["pollId", "pollStatus"],
  components: { ResultBar, PollItemHeader, ThreadLink, PollItemFooter, Btn },

  setup(props) {
    const state = reactive({
      pollObj: null,
      pollId: props.pollId,
      pollStatus: props.pollStatus,
    });
    const unsub = onSnapshot(
      doc(db, "polls-" + state.pollStatus, state.pollId),
      (doc) => {
        state.pollObj = doc.data();
      }
    );
    const showResult = () => {
      return getShowResult(state.pollId);
    };

    const getResultCount = (index) => {
      return state.pollObj["count_" + index];
    };
    const getTopResult = (index) => {
      let indexTop = 0;
      let countTop = state.pollObj["count_0"];
      state.pollObj.options.forEach((item, index) => {
        if (state.pollObj["count_" + item.index] > countTop) {
          indexTop = item.index;
          countTop = state.pollObj["count_" + item.index];
        }
      });
      return indexTop;
    };

    const removePoll = () => {
      console.log("remove poll");
      submitPass(props.pollId, false);
    };
    const votePoll = () => {
      console.log("vote poll");
    };

    const clickDiv = () => {
      console.log("click div");
    };

    return {
      state,
      showResult,
      getResultCount,
      getTopResult,
      removePoll,
      votePoll,
      clickDiv,
    };
  },
};
</script>

<style>
</style>