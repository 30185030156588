<template>
  <div class="about absolute bg-gray-50 overflow-y-auto">
    <!-- <div class="bg-theme">
      <div class="flex items-center h-12 justify-between max-w-sm mx-auto">
        <div class="flex items-center justify-between ml-2 sm:ml-0">
          <div class="flex items-center cursor-pointer mr-4">
            <img
              class="h-8 rounded-full"
              src="@/assets/op_icon.png"
              alt=""
              @click="showAbout()"
            />
            <img
              class="h-8 rounded-full ml-2"
              src="@/assets/oipolly.png"
              alt=""
              @click="showAbout()"
            />
          </div>
        </div>
        <div class="mr-2">
          <btn
            icon="close"
            color="white"
            iconSize="medium"
            @clickClose="closeAbout"
            emitName="clickClose"
          />
        </div>
      </div>
    </div> -->
    <div class="px-4 bg-white pb-12">
      <div class="mt- pt-4 max-w-sm mx-auto">
        <div class="">
          <div class="text-default font-semibold text-xl">What's YoPolly?</div>
          <div class="text-gray-500 text-xs mt-2 mb-6">
            <div class="">
              We publish polls on big things and little things, topical and
              timeless. <br /><br />No bias, no agenda - other than to take a
              pulse on what people think about things.
            </div>
          </div>
        </div>
        <div class="">
          <div class="text-default font-semibold text-xl">Suggest a poll</div>
          <div class="text-gray-500 text-xs mt-2 mb-6">
            <div class="">If you have any ideas for a poll, let us know.</div>
            <br />
            <input-base
              :key="state.updateCount"
              full
              textbox
              placeholdertext="Suggested poll..."
              emitName="updateSuggestedPoll"
              @updateSuggestedPoll="updateSuggestedPoll"
            />
            <div class="flex justify-between">
              <transition>
                <div
                  v-if="state.suggestionReceived"
                  class="text-positive font-semibold"
                >
                  Thanks for your suggestion!
                </div>
              </transition>
              <div class=""></div>
              <btn
                color="theme"
                fill
                @postSuggestion="postSuggestion"
                emitName="postSuggestion"
                class="mr-2 sm:mr-6 -mt-2"
                buttonText="Send"
                :disabled="state.suggestion == ''"
              />
            </div>
          </div>
        </div>
        <div class="">
          <div class="text-default font-semibold text-xl">Feedback</div>
          <div class="text-gray-500 text-xs mt-2 mb-6">
            <div class="">
              We're new and learning - would love to hear any thoughts you have
              about the site.
            </div>
            <br />
            <input-base
              :key="state.updateCount"
              full
              textbox
              placeholdertext="Feedback..."
              emitName="updateFeedback"
              @updateFeedback="updateFeedback"
            />
            <div class="flex justify-between">
              <transition>
                <div
                  v-if="state.feedbackReceived"
                  class="text-positive font-semibold"
                >
                  Thanks for your feedback!
                </div>
              </transition>
              <div class=""></div>
              <btn
                color="theme"
                fill
                @postFeedback="postFeedback"
                emitName="postFeedback"
                class="mr-2 sm:mr-6 -mt-2"
                buttonText="Send"
                :disabled="state.feedback == ''"
              />
            </div>
          </div>
        </div>
        <div class="text-gray-500 text-xs mt-2 mb-6">
          <div class="text-xs">&copy; Copyright 2022</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "../components/ui/buttons/btn.vue";
import InputBase from "../components/ui/inputs/input-base.vue";
import { reactive } from "vue";
import { sendSuggestion, sendFeedback } from "../db/firebase";
export default {
  components: {
    Btn,
    InputBase,
  },
  setup(props, context) {
    const state = reactive({
      suggestion: "",
      feedback: "",
      suggestionReceived: false,
      feedbackReceived: false,
      updateCount: 0,
    });
    const closeAbout = () => {
      context.emit("closeAbout");
    };
    const updateSuggestedPoll = (text) => {
      state.suggestion = text;
    };
    const updateFeedback = (text) => {
      state.feedback = text;
    };
    const postSuggestion = () => {
      sendSuggestion(state.suggestion);
      state.suggestion = "";
      state.suggestionReceived = true;
      setTimeout(() => {
        state.suggestionReceived = false;
        state.updateCount += 1;
      }, "2000");
    };
    const postFeedback = () => {
      sendFeedback(state.feedback);
      state.feedback = "";
      state.feedbackReceived = true;
      setTimeout(() => {
        state.feedbackReceived = false;
        state.updateCount += 1;
      }, "2000");
    };
    return {
      closeAbout,
      updateSuggestedPoll,
      postSuggestion,
      updateFeedback,
      postFeedback,
      state,
    };
  },
};
</script>

