<template>
  <div class="bg-white fixed inset-0">
    <div class="max-w-sm min-w-sm mx-auto pt-24 flex justify-center text-white">
      <div class="flex-col items-center justify-center">
        <!-- <div class="text-3xl font-thin">
          Welcome to <span class="font-normal">OiPolly</span>
        </div>
        <div class="text- font-bold text-center pt-2">Polls on things</div> -->
        <div class="flex justify-center">
          <div class="text-sm text-theme text-center pt-16">Loading polls</div>
          <div
            v-for="index in state.count"
            :key="index"
            class="text-sm font-bold text-center pt-16 ml-1"
            :class="getColor(index)"
          >
            .
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, onUnmounted } from "vue";
export default {
  setup() {
    const state = reactive({
      counter: 0,
      count: 5,
      intervalId: null,
    });
    const getColor = (index) => {
      return index >= state.counter + 1 ? "text-themefaded" : "text-theme";
    };
    const countdown = () => {
      if (state.counter == state.count) {
        state.counter = 0;
      } else {
        state.counter = state.counter + 1;
      }
    };
    onMounted(() => {
      state.intervalId = setInterval(countdown, 750);
    });
    onUnmounted(() => {
      clearInterval(state.intervalId);
    });
    return {
      state,
      getColor,
    };
  },
};
</script>

<style>
</style>