<template>
  <div v-if="state.pollObj" class="flex flex-col min-h-full relative mb-24">
    <div class="">
      <poll-header
        :title="state.pollObj.title"
        :date="state.pollObj.date"
        :votes="state.pollObj.totalVotes"
        :status="state.pollObj.status"
        :img="state.pollObj.thumbURL"
        closeModal="closeModal"
      />
    </div>
    <div class="modal-body pt-">
      <div v-if="state.pollObj.info" class="px-4 sm:px-8 pt-4">
        <div class="text-xs text-gray-900 pb-2">
          {{ state.pollObj.info }}
        </div>
      </div>

      <div v-if="state.showMainBody && !playMode.transition">
        <div class="" v-if="!showResult()">
          <div class="px-4 sm:px-8 pt-4">
            <standard-detail
              v-if="state.pollObj.type == 'standard'"
              :poll="state.pollObj"
              :showResult="false"
            />
            <binary-image-detail
              v-if="state.pollObj.type == 'binary-image'"
              :poll="state.pollObj"
              :showResult="false"
            />
            <binary-text-detail
              v-if="state.pollObj.type == 'binary-text'"
              :poll="state.pollObj"
              :showResult="false"
            />
            <score-detail
              v-if="state.pollObj.type == 'score'"
              :poll="state.pollObj"
              :showResult="false"
            />
            <scale-detail
              v-if="state.pollObj.type == 'scale'"
              :poll="state.pollObj"
              :showResult="false"
            />
          </div>
          <div class="mt-4">
            <btn
              class="ml-2 sm:ml-2"
              @selectPass="selectPass"
              emitName="selectPass"
              :iconLabel="passOption.text"
              :color="passOption.type"
              icon="trash"
              :iconFirst="true"
            />
          </div>
        </div>

        <div class="" v-if="showResult()">
          <div class="px-4 pt-4">
            <standard-detail
              v-if="state.pollObj.type == 'standard'"
              :poll="state.pollObj"
              :showResult="true"
            />
            <binary-image-detail
              v-if="state.pollObj.type == 'binary-image'"
              :poll="state.pollObj"
              :showResult="true"
            />
            <binary-text-detail
              v-if="state.pollObj.type == 'binary-text'"
              :poll="state.pollObj"
              :showResult="true"
            />
            <score-detail
              v-if="state.pollObj.type == 'score'"
              :poll="state.pollObj"
              :showResult="true"
            />
            <scale-detail
              v-if="state.pollObj.type == 'scale'"
              :poll="state.pollObj"
              :showResult="true"
            />
          </div>
          <div v-if="true" class="px-8 py-8 mt-4 border-gray-200 mb-16">
            <div
              class="relative mb-4 comment-box h-full"
              :class="{ fade: state.fadeCommentBox }"
            >
              <input-base
                :key="state.updateCount"
                label=""
                full
                textbox
                small
                placeholdertext="Add a comment..."
                emitName="addCommentText"
                @addCommentText="addCommentText"
              />
              <div class="flex justify-between w-full -mt-4">
                <transition>
                  <div
                    v-if="state.commentPosted"
                    class="text-positive text-xs mt-2"
                  >
                    Comment posted
                  </div>
                </transition>
                <div class=""></div>
                <btn
                  @handlePostComment="handlePostComment"
                  emitName="handlePostComment"
                  buttonText="Post"
                  bold
                  fill
                  :disabled="state.comment == ''"
                />
              </div>
            </div>
            <div class="text-xs uppercase font-semibold text-gray-400 pb-1">
              <div v-if="state.comments.length">Comments</div>
              <div v-else>No comments yet</div>
            </div>
            <div
              class=""
              v-for="(comment, index) in state.comments"
              :key="index"
            >
              <div class="pt-2 pb-1 border-b border-gray-200">
                <div class="px-2">
                  <div class="text-xs text-gray-400 mb-1">
                    {{ dateString(comment.date) }}
                  </div>
                  <div class="text-xs text-newline">
                    {{ comment.comment }}
                  </div>
                  <div class="flex items-center -ml-2 mt-1">
                    <btn
                      @handleUpvoteComment="handleUpvoteComment(comment.id)"
                      emitName="handleUpvoteComment"
                      bold
                      small
                      icon="thumb-up"
                      iconSize="small"
                      color="positivefaded"
                    />
                    <div class="text-xs text-positive">
                      {{ comment.upvotes }}
                    </div>
                    <btn
                      @handleDownvoteComment="handleDownvoteComment(comment.id)"
                      emitName="handleDownvoteComment"
                      bold
                      small
                      icon="thumb-down"
                      iconSize="small"
                      color="negativefaded"
                      class="ml-4"
                    />
                    <div class="text-xs text-negativefaded">
                      {{ comment.downvotes }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 right-0 left-0 pb-2 border-t bg-gray-50 z-100">
        <div
          v-if="playMode.mode && !playMode.transition"
          class="w-full bg-gray-200 flex"
        >
          <div
            :style="getWidthStyle()"
            class="
              text-xs
              leading-none
              text-center text-white
              transition-color transition-slowest
              ease
              w-0
              h-2
            "
            :class="'bg-' + getTimerColor()"
          ></div>
        </div>
        <div class="max-w-sm mx-auto mt-2">
          <!-- <div class="ml-4">
            <btn
              icon="bar-chart"
              iconLabel="Your stats"
              @gotoStats="gotoStats"
              emitName="gotoStats"
              :outlined="true"
              :color="enableShowStats() ? 'default' : 'buttonpass'"
            />
          </div> -->
          <div class="flex items-center justify-between">
            <btn
              icon="chevron-left"
              iconLabel="PREV"
              :iconFirst="true"
              :bold="true"
              color="theme"
              iconSize="medium"
              @gotoPrevPoll="gotoPrevPoll"
              emitName="gotoPrevPoll"
              :disabled="isPrevDisabled()"
            />
            <div class="flex">
              <btn
                v-if="playMode.timer == 'paused' && playMode.mode"
                icon="play"
                @clickPlay="clickPlay"
                emitName="clickPlay"
                :outlined="true"
                :color="enableShowStats() ? 'default' : 'buttonpass'"
                :disabled="!(playMode.timer == 'paused') && playMode.mode"
              />
              <btn
                v-if="playMode.mode && playMode.timer != 'paused'"
                class="mx-6"
                icon="pause"
                :outlined="true"
                @clickPause="clickPause"
                emitName="clickPause"
                :color="enableShowStats() ? 'default' : 'buttonpass'"
                :disabled="playMode.timer == 'paused' || !playMode.mode"
              />
              <!-- <btn
                icon="stop"
                :outlined="true"
                @clickStop="clickStop"
                emitName="clickStop"
                :color="enableShowStats() ? 'default' : 'buttonpass'"
                :disabled="!playMode.mode"
              /> -->
            </div>

            <btn
              :bold="true"
              icon="chevron-right"
              iconLabel="NEXT"
              :iconFirst="false"
              color="theme"
              iconSize="medium"
              @gotoNextPoll="gotoNextPoll"
              emitName="gotoNextPoll"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="grow-0">
      <!-- <footer-preview
        v-if="state.pollObj.status == 'preview'"
        @editPoll="editPoll"
        @pushStage="pushStage"
      /> -->
      <footer-stage
        v-if="state.pollObj.status == 'stage'"
        @publish="publish"
        @deletePoll="deletePoll"
      />
    </div>
  </div>
  <div v-else class="flex h-full justify-center items-center">
    <loading />
  </div>
</template>

<script>
import { reactive, onMounted, ref, toRef, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import PollHeader from "./poll-header.vue";
import FooterPreview from "../poll-detail/footer-preview.vue";
import FooterStage from "../poll-detail/footer-stage.vue";
import StandardDetail from "../standard/standard-detail.vue";
import BinaryImageDetail from "../binary-image/binary-image-detail.vue";
import BinaryTextDetail from "../binary-text/binary-text-detail.vue";
import ScoreDetail from "../score/score-detail.vue";
import ScaleDetail from "../scale/scale-detail.vue";
import Btn from "../../ui/buttons/btn.vue";
import { publishPoll } from "../../../db/new-poll/new-poll";
import {
  postComment,
  fetchComments,
  newCommentText,
  updateNewComment,
  upvoteComment,
  downvoteComment,
} from "../../../db/comments";
import { getDateString } from "../../../db/utils";
import {
  getShowResult,
  db,
  uid,
  answeredPolls,
  submitPass,
  navLog,
} from "../../../db/firebase";
import {
  playMode,
  initPlayMode,
  pausePlayMode,
  resumePlayMode,
  endPlayMode,
  transitionEnd,
  nextPoll,
  prevPoll,
  pauseForStats,
  enableShowStats,
} from "../../../db/play";
import { doc, onSnapshot } from "firebase/firestore";
import InputBase from "../../ui/inputs/input-base.vue";
import Loading from "../../ui/loading.vue";
import Timer from "../../ui/timer/donut-timer.vue";
import Icon from "../../ui/icons/icon.vue";
import ResultBar from "../results/result-bar.vue";
// import ImgDetail from "../../ui/img/img-detail.vue";
// import * as Standard from "../../../db/new-poll/standard";
// import * as BinaryImage from "../../../db/new-poll/binary-image";
// import * as Score from "../../../db/new-poll/score";
export default {
  props: ["poll"],
  components: {
    PollHeader,
    // FooterPreview,
    FooterStage,
    StandardDetail,
    ScoreDetail,
    ScaleDetail,
    InputBase,
    Btn,
    // Icon,
    BinaryImageDetail,
    BinaryTextDetail,
    Loading,
    // Timer,
    // ResultBar,
  },

  setup(props, { emit }) {
    let state = reactive({
      pollObj: null,
      comment: "",
      comments: [],
      showResult: false,
      showAddcomment: true,
      addCommentSuccess: false,
      fadeCommentBox: false,
      showMainBody: false,
      answeredPolls: answeredPolls,
      commentPosted: false,
      updateCount: 0,
    });
    const route = useRoute();
    const router = useRouter();
    let pollId = route.params.pollId;

    const passOption = {
      text: "Not interested",
      type: "buttonpass",
    };

    const isPrevDisabled = () => {
      if (!playMode.mode) {
        return false;
      }
      return playMode.previousPolls.length < 1;
    };
    const gotoStats = () => {
      pauseForStats();
      // pausePlayMode();
      // emit("showStatsFromPlay");
    };
    const gotoPrevPoll = () => {
      prevPoll(route.params.pollId);
    };
    const gotoNextPoll = () => {
      nextPoll(false, route.params.pollId);
    };

    const clickClose = () => {
      emit("clickClose");
    };

    watch(
      () => route.params,
      () => {
        // console.log("poll detail", route.params, route.name);
        pollId = route.params.pollId;
        if (route.name == "Preview") {
          // console.log("preview");
          const poll = toRef(props, poll);
          state.pollObj = poll;
          state.showMainBody = true;
          // console.log(poll);
        }

        if (route.name == "StageDetail") {
          const unsub_poll = onSnapshot(
            doc(db, "polls-stage", pollId),
            (doc) => {
              state.pollObj = doc.data();
              state.showMainBody = true;
            }
          );
        }

        if (route.name == "PollFeedDetail" || route.name == "ThreadDetail") {
          // console.log("inside here");
          const unsub_poll = onSnapshot(
            doc(db, "polls-live", pollId),
            (doc) => {
              // console.log("WATCH listener - poll-detail.vue", pollId);
              state.pollObj = doc.data();
              // console.log(state.pollObj);
              transitionEnd();
              // setTimeout(() => {
              //   transitionEnd();
              // }, 100);
            }
          );
          // let ref = db.collection("users").doc(uid).collection("polls").doc(uid);
          fetchComments(pollId).then((res) => {
            state.comments = res;
          });
          // const unsub_votelog = onSnapshot(doc(db, "voted-log", uid), (doc) => {
          // let answeredPollsList = doc.data().polls;
          let check = state.answeredPolls.answeredPolls.indexOf(pollId);
          if (check == -1) {
            state.showResult = false;
            state.showMainBody = true;
          } else {
            state.showResult = true;
            state.showMainBody = true;
          }
          // });
        }
      }
    );
    if (route.name == "Preview") {
      console.log("preview");
      const poll = toRef(props, poll);
      state.pollObj = poll;
      state.showMainBody = true;
      console.log(poll);
    }

    if (route.name == "StageDetail") {
      const unsub_poll = onSnapshot(doc(db, "polls-stage", pollId), (doc) => {
        state.pollObj = doc.data();
        state.showMainBody = true;
      });
    }

    if (route.name == "PollFeedDetail" || route.name == "ThreadDetail") {
      const unsub_poll = onSnapshot(doc(db, "polls-live", pollId), (doc) => {
        // console.log("ORIGINAL listener - poll-detail.vue");
        state.pollObj = doc.data();
        transitionEnd();
      });
      // let ref = db.collection("users").doc(uid).collection("polls").doc(uid);
      fetchComments(pollId).then((res) => {
        state.comments = res;
      });
      // const unsub_votelog = onSnapshot(doc(db, "voted-log", uid), (doc) => {
      // let answeredPollsList = doc.data().polls;
      let check = state.answeredPolls.answeredPolls.indexOf(pollId);
      if (check == -1) {
        state.showResult = false;
        state.showMainBody = true;
      } else {
        state.showResult = true;
        state.showMainBody = true;
      }
      // });
    }

    const selectPass = () => {
      // console.log("not interested");
      submitPass(pollId);
    };

    const showResult = () => {
      let check = state.answeredPolls.answeredPolls.indexOf(pollId);
      return check != -1;
      // if (check == -1) {
      //   state.showResult = false;
      //   state.showMainBody = true;
      // } else {
      //   state.showResult = true;
      //   fetchComments(pollId).then((res) => {
      //     state.comments = res;
      //     state.showMainBody = true;
      //   });
      // }
    };

    const addCommentText = (val) => {
      state.comment = val;
    };

    const handleShowResult = () => {
      // console.log("handle...");
      const showRes = getShowResult(pollId);
      return showRes;
    };

    const dateString = (date) => {
      return getDateString(date);
    };

    const handlePostComment = () => {
      postComment(pollId, state.comment).then((res) => {
        // console.log(res);
        state.updateCount += 1;
        state.commentPosted = true;
        state.comment = "";
        fetchComments(pollId).then((res) => {
          // console.log(state.res);
          state.comments = res;
          // state.addCommentSuccess = true;
          setTimeout(() => {
            state.commentPosted = false;
          }, "1500");
          // setTimeout(() => {
          //   state.showAddcomment = false;
          // }, "2000");
        });
      });
    };

    const handleUpvoteComment = (id) => {
      upvoteComment(pollId, id).then(() => {
        fetchComments(pollId).then((res) => {
          state.comments = res;
        });
      });
    };

    const handleDownvoteComment = (id) => {
      downvoteComment(pollId, id).then(() => {
        fetchComments(pollId).then((res) => {
          state.comments = res;
        });
      });
    };

    const editPoll = () => {
      router.back();
    };

    const publish = () => {
      const stageId = route.params.pollId;
      publishPoll(state.pollObj, stageId).then((res) => {
        router.back();
      });
    };

    const deletePoll = () => {
      // console.log("delete poll");
    };

    const closeModal = () => {
      router.push("/");
      // console.log("close modal?");
    };

    // const togglePlay = () => {
    //   if (playMode.timer == "running") {
    //     pausePlay();
    //   } else {
    //     resumePlay();
    //   }
    // };

    // const pausePlay = () => {
    //   // console.log("pause");
    //   state.paused = true;
    //   pausePlayMode();
    // };
    // const resumePlay = () => {
    //   // console.log("resume");
    //   resumePlayMode();
    // };

    const clickPlay = () => {
      state.paused = false;
      if (playMode.mode) {
        resumePlayMode();
      } else {
        initPlayMode();
      }
    };

    const clickPause = () => {
      state.paused = true;
      pausePlayMode();
    };

    const clickStop = () => {
      endPlayMode();
    };

    const getTimerColor = () => {
      if (playMode.timeRemaining < 3000) {
        return "negative";
      } else if (playMode.timeRemaining < 6000) {
        return "amber";
      } else {
        return "theme";
      }
    };

    const getWidthStyle = () => {
      const pct =
        Math.round((playMode.timeRemaining / 12000) * 1000) / 10 + "%";
      const obj = { width: pct };
      // console.log(obj);
      return obj;
    };

    onMounted(() => {
      navLog("Loaded poll: " + pollId);
    });

    return {
      state,
      showResult,
      addCommentText,
      handleShowResult,
      dateString,
      handlePostComment,
      handleUpvoteComment,
      handleDownvoteComment,
      editPoll,
      // pushStage,
      publish,
      deletePoll,
      closeModal,
      passOption,
      selectPass,
      // togglePlay,
      playMode,
      isPrevDisabled,
      gotoPrevPoll,
      gotoNextPoll,
      gotoStats,
      enableShowStats,
      clickClose,
      getTimerColor,
      getWidthStyle,
      // pausePlay,
      clickPlay,
      clickStop,
      clickPause,
    };
  },
};
</script>

<style>
.modal-body {
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 100px;
  opacity: 1;
}
.comment-box {
  transition-duration: 1.5s;
}
.comment-box.fade {
  opacity: 0;
  /* transform: translateY(-100px); */
}
.text-newline {
  white-space: pre-wrap;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>