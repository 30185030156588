<template>
  <div class="w-full flex">
    <div class="text-xs leading-none w-6 flex-grow-0 text-center">
      {{ text }}
    </div>
    <div class="flex-grow bg-gray-200 mr-1 shadow flex">
      <div
        :style="getWidthStyle"
        class="
          text-xs
          leading-none
          py-
          text-center text-white
          transition-width transition-slowest
          ease
          w-0
        "
        :class="getBarClass()"
      ></div>
    </div>
    <div class="text-xs leading-none w-8 text-right" :class="getTextClasss()">
      {{ getWidthStr() }}
    </div>
  </div>
</template>

<script>
import { getColor } from "../../../db/utils";
export default {
  props: [
    "text",
    "count",
    "totalVotes",
    "isTopResult",
    "type",
    "id",
    "reversed",
  ],
  computed: {
    getWidthStyle: function () {
      const obj = { width: `${this.getWidthStr()}` };
      return obj;
    },
  },
  methods: {
    getWidthStr() {
      const pct = Math.round((this.count / this.totalVotes) * 100) / 1;
      return `${pct}%`;
    },
    getBarClass() {
      let isFaded = false;
      if (!this.isTopResult) {
        isFaded = true;
      }
      return getColor("bg", this.type, isFaded);
    },
    getTextClasss() {
      if (this.isTopResult) {
        return "font-semibold";
      } else {
        return "text-gray-500";
      }
    },
  },
};
</script>

<style>
.reversed {
  /* transform: rotate(180deg); */
  justify-content: end;
}
</style>