<template>
  <div v-if="newPoll" class="flex flex-col min-h-full">
    <div class="">
      <poll-header
        :title="newPoll.title"
        :date="newPoll.date"
        :votes="newPoll.totalVotes"
        :status="newPoll.status"
        closeModal="closeModal"
      />
    </div>
    <div class="modal-body">
      <div v-if="newPoll.info" class="px-8 pt-4">
        <div class="text-xs text-gray-900">
          {{ newPoll.info }}
        </div>
      </div>
      <div v-if="state.showMainBody">
        <div class="">
          <div class="px-8 pt-4">
            <standard-detail
              v-if="poll.type == 'standard'"
              :poll="poll"
              :showResult="false"
            />
            <binary-image-detail
              v-if="poll.type == 'binary-image'"
              :poll="poll"
              :showResult="false"
            />
            <binary-text-detail
              v-if="poll.type == 'binary-text'"
              :poll="poll"
              :showResult="false"
            />
            <score-detail
              v-if="poll.type == 'score'"
              :poll="poll"
              :showResult="false"
            />
            <scale-detail
              v-if="poll.type == 'scale'"
              :poll="poll"
              :showResult="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="grow-0">
      <footer-preview
        v-if="newPoll.status == 'preview'"
        @editPoll="editPoll"
        @pushStage="pushStage"
      />
      <footer-stage v-if="newPoll.status == 'stage'" @publish="publish" />
    </div>
  </div>
  <div v-else class="flex h-full justify-center items-center">
    <loading />
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import PollHeader from "./poll-header.vue";
import FooterPreview from "../poll-detail/footer-preview.vue";
import FooterStage from "../poll-detail/footer-stage.vue";
import StandardDetail from "../standard/standard-detail.vue";
import BinaryImageDetail from "../binary-image/binary-image-detail.vue";
import BinaryTextDetail from "../binary-text/binary-text-detail.vue";
import ScoreDetail from "../score/score-detail.vue";
import ScaleDetail from "../scale/scale-detail.vue";
import * as Standard from "../../../db/new-poll/standard";
import * as BinaryImage from "../../../db/new-poll/binary-image";
import * as BinaryText from "../../../db/new-poll/binary-text";
import * as Score from "../../../db/new-poll/score";
import * as Scale from "../../../db/new-poll/scale";
import Loading from "../../ui/loading.vue";
export default {
  props: ["poll", "newPoll"],
  components: {
    PollHeader,
    FooterPreview,
    FooterStage,
    StandardDetail,
    BinaryImageDetail,
    BinaryTextDetail,
    ScoreDetail,
    ScaleDetail,
    Loading,
  },

  setup(props) {
    let state = reactive({
      pollObj: null,
      comment: "",
      comments: [],
      showResult: false,
      showAddcomment: true,
      addCommentSuccess: false,
      fadeCommentBox: false,
      showMainBody: false,
    });
    const route = useRoute();
    const router = useRouter();
    const pollId = route.params.pollId;

    if (route.name == "Preview") {
      console.log("preview");
      console.log(props.poll.type);
      state.showMainBody = true;
    }

    const editPoll = () => {
      router.back();
    };

    const pushStage = () => {
      const pollType = props.poll.type;
      if (pollType == "binary-image") {
        BinaryImage.uploadPoll();
        router.back();
      }
      if (pollType == "standard") {
        Standard.uploadPoll();
        router.back();
      }
      if (pollType == "binary-text") {
        BinaryText.uploadPoll();
        router.back();
      }
      if (pollType == "score") {
        Score.uploadPoll();
        router.back();
      }
      if (pollType == "scale") {
        Scale.uploadPoll();
        router.back();
      }
    };

    const closeModal = () => {
      console.log("close modal");
    };

    return {
      state,
      editPoll,
      pushStage,
      closeModal,
    };
  },
};
</script>

<style>
.modal-body {
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 100px;
  opacity: 1;
}
.comment-box {
  transition-duration: 1.5s;
}
.comment-box.fade {
  opacity: 0;
  /* transform: translateY(-100px); */
}
.text-newline {
  white-space: pre-wrap;
}
</style>