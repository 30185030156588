<template>
  <custom-header class="" isfooter>
    <template v-slot:header-main>
      <div class="flex justify-end mr-4">
        <header-element class="relative mr-4">
          <btn
            class=""
            @deletePoll="deletePoll"
            emitName="deletePoll"
            buttonText="Delete"
            bold
            color="negative"
          />
        </header-element>
        <header-element class="relative">
          <btn
            class=""
            @publish="publish"
            emitName="publish"
            buttonText="Publish"
            bold
            fill
          />
        </header-element>
      </div>
    </template>
  </custom-header>
</template>

<script>
import CustomHeader from "../../layout/custom-header.vue";
import HeaderElement from "../../layout/header-element.vue";
import Btn from "../../ui/buttons/btn.vue";
export default {
  components: {
    CustomHeader,
    HeaderElement,
    Btn,
  },
  methods: {
    deletePoll() {
      this.$emit("deletePoll");
    },
    publish() {
      this.$emit("publish");
    },
  },
};
</script>

<style>
</style>