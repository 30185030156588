<template>
  <div class="text-xs pb-1 font-medium text-gray-600">{{ label }}</div>
</template>

<script>
export default {
  props: {
    label: String,
    optional: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>