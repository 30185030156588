<template>
  <div class="relative z-0">
    <svg
      :width="getSVGSize()"
      :height="getSVGSize()"
      class="pie-container z-0 shadow rounded-full"
    >
      <circle
        id="bg"
        :r="circleCentre()"
        :cx="circleCentre()"
        :cy="circleCentre()"
        fill="#e5e7eb"
      />
      <circle
        id="p12"
        :r="circleRadius()"
        :cx="circleCentre()"
        :cy="circleCentre()"
        :stroke="getColor(type)"
        :stroke-width="circleCentre()"
        fill="none"
        :stroke-dasharray="getStrokeArray()"
        stroke-dashoffset="0"
      />
    </svg>
    <div class="absolute" :class="getInnerPosition()">
      <div
        class="bg-white rounded-full flex justify-center items-center text-3xl"
        :class="getInnerSize()"
      >
        <div class="" :class="getInnerTextClass()">
          {{ avgScore }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: null,
    },
    topCount: {
      type: Number,
      default: 0,
    },
    totalVotes: {
      type: Number,
      default: 0,
    },
    avgScore: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      size: "104  ",
    };
  },
  methods: {
    getSVGSize() {
      return this.small ? "104 " : "200";
      // return "64";
    },
    circleCentre() {
      return this.getSVGSize() / 2;
    },
    circleRadius() {
      return this.getSVGSize() / 4;
    },
    getInnerPosition() {
      return this.small ? "top-3 left-3" : "top-6 left-6";
    },
    getInnerSize() {
      return this.small ? "w-20 h-20" : "w-32 h-32";
    },
    getInnerTextClass() {
      let res1;
      this.small ? (res1 = "font-bold") : (res1 = "text-3xl");
      let res2 = this.type;
      return "text-" + res2 + " " + res1;
    },
    getStrokeArray() {
      let size;
      if (this.small) {
        size = 26;
      } else {
        size = 32;
      }
      let res = 2 * 3.14 * size;
      // console.log("avg: ", this.avgScore);
      // res = res * (this.topCount / this.totalVotes);
      res = res * (parseFloat(this.avgScore) / 6);
      // res = 157 / 2;
      res = res.toString();
      // console.log(item.count / this.totalVotes);
      // console.log(res);
      return res + " 314";
      // return "10";
    },
    getPercent() {
      console.log(this.segment);
      // const top = this.segment;
      // let res = Math.round((100 * this.topCount) / this.totalVotes);
      // return res.toString() + "%";
    },
    getColor(type) {
      const colors = {
        theme: "#12567a",
        default: "#00c7e6",
        positive: "#69B578",
        neutral: "#FFC100",
        negative: "#FF312E",
      };
      return colors[type];
    },
  },
};
</script>

<style>
.pie-container {
  transform: rotate(-0.25turn);
}
@keyframes animate_p1 {
  to {
    /* stroke-dasharray: 78.5 314; */
  } /* 314 ÷ 4 = 78.5 */
}
#bg {
  fill: #e5e7eb;
}
#p1 {
  stroke-dasharray: 0 314; /* 2π × 50 ≈ 314 */
  stroke-dashoffset: 0;
  animation: animate_p1 1s linear forwards;
}
</style>