<template>
  <div class="h-12 flex flex-col justify-center">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>