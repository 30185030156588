<template>
  <div class="">
    <div v-if="!showResult">
      <div v-if="state.loading" class="flex justify-center mt-12">
        <loading />
      </div>
      <div class="pb-" v-else>
        <standard-option
          v-for="option in state.pollObj.options"
          :key="option.index"
          :option="option"
          :live="state.pollObj.status == 'live'"
          @selectOption="selectOption"
        />
      </div>
    </div>
    <div v-else>
      <div class="">
        <result-bar
          v-for="option in state.pollObj.options"
          :key="option.index"
          :text="option.text"
          :count="getResultCount(option.index)"
          :totalVotes="state.pollObj.totalVotes"
          :isTopResult="option.index == getTopResult()"
          :type="option.type"
          class="mb-3"
          :img="option.optionImg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import StandardOption from "./standard-option.vue";
import { uploadPoll } from "../../../db/new-poll/standard";
import { db, submitVote } from "../../../db/firebase";
import Loading from "../../ui/loading.vue";
import ResultBar from "../results/result-bar.vue";
import { doc, onSnapshot } from "firebase/firestore";
export default {
  props: ["poll", "showResult"],
  components: {
    StandardOption,
    Loading,
    ResultBar,
  },
  setup(props) {
    const state = reactive({
      loading: false,
      pollObj: props.poll,
    });
    const route = useRoute();
    let pollId;
    if (route.name == "PollFeedDetail" || route.name == "ThreadDetail") {
      pollId = route.params.pollId;
      const unsub = onSnapshot(doc(db, "polls-live", pollId), (doc) => {
        state.pollObj = doc.data();
      });
    }

    const selectOption = async (option) => {
      state.loading = true;
      // console.log("select option");
      submitVote(pollId, option.index).then(() => {
        // console.log("submitted");
      });
    };
    const getResultCount = (index) => {
      return state.pollObj["count_" + index];
    };

    const getTopResult = (index) => {
      let indexTop = 0;
      let countTop = state.pollObj["count_0"];
      state.pollObj.options.forEach((item, index) => {
        if (state.pollObj["count_" + item.index] > countTop) {
          indexTop = item.index;
          countTop = state.pollObj["count_" + item.index];
        }
      });
      return indexTop;
    };

    return {
      state,
      selectOption,
      getResultCount,
      getTopResult,
    };
  },
  methods: {
    publishPoll() {
      console.log("publish");
      uploadPoll();
    },
    editPoll() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>