<template>
  <div class="">
    <div v-if="state.loading" class="flex justify-center mt-16">
      <loading />
    </div>
    <div v-else class="">
      <div class="h-full">
        <div v-if="!showResult" class="flex">
          <div class="w-1/2 text-xs font-semibold text-center mb-1">
            {{ poll.options[0].text }}
          </div>
          <div class="w-1/2 text-xs font-semibold text-center mb-1">
            {{ poll.options[1].text }}
          </div>
        </div>
        <div v-else class="flex mb-2">
          <result-bar
            :key="0"
            :text="poll.options[0].text"
            :count="getResultCount(0)"
            :totalVotes="state.pollObj.totalVotes"
            :isTopResult="poll.options[0].index == getTopResult()"
            :type="poll.options[0].type"
            :reversed="true"
            class="mr-1"
          />
          <result-bar
            :key="1"
            :text="poll.options[1].text"
            :count="getResultCount(1)"
            :totalVotes="state.pollObj.totalVotes"
            :isTopResult="poll.options[1].index == getTopResult()"
            :type="poll.options[1].type"
            class="ml-1"
          />
        </div>
        <div class="flex">
          <div
            :disabled="showResult"
            class="w-1/2 mr-1"
            @click="selectOption(0)"
            :class="{ 'button-ripple-unrounded cursor-pointer': !showResult }"
          >
            <img
              v-if="poll.options[0].imgURL"
              class="w-full h-full object-cover"
              :src="poll.options[0].imgURL"
            />
          </div>
          <div
            :disabled="showResult"
            class="w-1/2 ml-1"
            @click="selectOption(1)"
            :class="{ 'button-ripple-unrounded cursor-pointer': !showResult }"
          >
            <img
              v-if="poll.options[1].imgURL"
              class="w-full h-full object-cover"
              :src="poll.options[1].imgURL"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import { uploadPoll } from "../../../db/new-poll/binary-image";
import { db, submitVote } from "../../../db/firebase";
import Loading from "../../ui/loading.vue";
import ResultBar from "../results/result-bar.vue";
import { doc, onSnapshot } from "firebase/firestore";
export default {
  props: ["poll", "showResult", "pollId"],
  components: {
    Loading,
    ResultBar,
  },
  setup(props) {
    const state = reactive({
      loading: false,
      pollObj: props.poll,
      pollId: props.pollId,
    });

    const route = useRoute();
    let pollId;
    if (state.pollId) {
      pollId = state.pollId;
      const unsub = onSnapshot(doc(db, "polls-live", pollId), (doc) => {
        state.pollObj = doc.data();
      });
    } else if (route.name == "PollFeedDetail") {
      pollId = route.params.pollId;
      const unsub = onSnapshot(
        doc(db, "polls-live", route.params.pollId),
        (doc) => {
          state.pollObj = doc.data();
        }
      );
    }

    const selectOption = async (option) => {
      if (props.showResult) {
        return;
      }
      state.loading = true;
      // console.log("select option: ", option);
      submitVote(pollId, option).then(() => {
        // console.log("submitted");
      });
    };
    const getResultCount = (index) => {
      return state.pollObj["count_" + index];
    };

    const getTopResult = (index) => {
      let indexTop = 0;
      let countTop = state.pollObj["count_0"];
      state.pollObj.options.forEach((item, index) => {
        if (state.pollObj["count_" + item.index] > countTop) {
          indexTop = item.index;
          countTop = state.pollObj["count_" + item.index];
        }
      });
      return indexTop;
    };

    return {
      state,
      selectOption,
      getResultCount,
      getTopResult,
    };
  },
  methods: {
    publishPoll() {
      console.log("publish");
      uploadPoll();
    },
    editPoll() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>