<template>
  <!-- <router-link :to="getRouterLink()" class="w-full"> -->
  <div
    v-if="state.pollId"
    class="rounded shadow mt-4 mx-4 sm:mx-0 max-w-md select-none bg-white"
  >
    <!-- <router-link :to="`/${path}/${state.pollId}`" v-if="state.pollId"> -->
    <!-- <router-link :to="'/' + pollId" v-if="state.pollId"> -->
    <div v-if="showPoll" class="">
      <standard-item
        v-if="pollType == 'standard'"
        :pollId="state.pollId"
        :pollStatus="state.pollStatus"
      />
      <binary-image-item
        v-if="pollType == 'binary-image'"
        :pollId="state.pollId"
        :pollStatus="state.pollStatus"
      />
      <binary-text-item
        v-if="pollType == 'binary-text'"
        :pollId="state.pollId"
        :pollStatus="state.pollStatus"
      />
      <score-item
        v-if="pollType == 'score'"
        :pollId="state.pollId"
        :pollStatus="state.pollStatus"
      />
      <scale-item
        v-if="pollType == 'scale'"
        :pollId="state.pollId"
        :pollStatus="state.pollStatus"
      />
    </div>
    <!-- </router-link> -->
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import StandardItem from "../standard/standard-item.vue";
import BinaryImageItem from "../binary-image/binary-image-item.vue";
import BinaryTextItem from "../binary-text/binary-text-item.vue";
import ScoreItem from "../score/score-item.vue";
import ScaleItem from "../scale/scale-item.vue";
export default {
  props: ["poll", "pollType", "path", "pollId", "pollStatus", "showPoll"],
  components: {
    StandardItem,
    BinaryImageItem,
    BinaryTextItem,
    ScoreItem,
    ScaleItem,
  },
  setup(props) {
    let state = reactive({
      pollId: props.pollId,
      pollStatus: props.pollStatus,
      pollType: props.pollType,
    });
    const getRouterLink = () => {
      if (props.path) {
        return `/${props.path}/${props.pollId}`;
      } else {
        return `/${props.pollId}`;
      }
    };
    return {
      state,
      getRouterLink,
    };
  },
};
</script>
  
<style>
</style>