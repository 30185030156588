import { reactive } from '@vue/reactivity';
import { db, uid, uploadImg, votedLog, pollsLive, increment, arrayUnion, deleteFromStage } from '../firebase';
import { Timestamp, collection, addDoc, setDoc, doc, deleteDoc, updateDoc  } from "firebase/firestore";
import { timeLimits, getDeadlineTime } from '../utils';
import { stageIds } from '../feed';
import { comment } from 'postcss';

let newPoll = reactive({
  country: false,
  title: null,
  info: null,
  status: "preview",
  totalVotes: 0,
  date: null,
  topic: null,
  timeLimit: 0,
  commentCount: 0,
  thread: false,
  newThread: false,
  newThreadDes: false,
  thumbURL: false,
  thumbBlob: null
})

const newPollReset = () => {
  newPoll.title = null,
  newPoll.info = null,
  newPoll.status = "preview",
  newPoll.totalVotes = 0,
  newPoll.date = null,
  newPoll.topic = null,
  newPoll.timeLimit = 0
  newPoll.thread = false,
  newPoll.newThread = false
  newPoll.newThreadDes = false
  newPoll.thumbURL = false,
  newPoll.thumbBlob = null  
}

const updateTitle = (title) => {
  newPoll.title = title
}
const updateInfo = (info) => {
  newPoll.info = info
}

const updateTopic = (topic) => {
  newPoll.topic = topic
}

const updateCountry = (country) => {
  if (country == "ALL"){
    newPoll.country = false
  } else {
    newPoll.country = country
  }
}

const updateThumb = (blob) => {
  newPoll.thumbURL = URL.createObjectURL(blob);
  newPoll.thumbBlob = blob
}

const updateTimeLimit = (timeLimit) => {
  let days
  timeLimits.forEach(item => {
    if (item.text == timeLimit) {
      days = item.val
    }
  })
  newPoll.timeLimit = days
}

const threadHandler = (thread, newThread = false) => {
  if (newThread){
    newPoll.thread = false
    newPoll.newThread = thread
  }
  if (thread && !newThread){
    newPoll.thread = thread
    newPoll.newThread = false
  }
  if (!thread){
    newPoll.thread = false
    newPoll.newThread = false
  }
}

const threadDescription = ( des) => {
  newPoll.threadDescription = des
}

const uploadPoll = async (mergePoll, docRefId) => {
  console.log(mergePoll)
  newPoll.date = Timestamp.fromDate(new Date())
  newPoll.creator = uid
  newPoll.totalVotes = 0  

  if (newPoll.thumbBlob){
    await uploadImg(docRefId + "_thumb", newPoll.thumbBlob).then(res => {
      newPoll.thumbURL = res
    })  
  } 
  delete newPoll['thumbBlob']   

  let poll = {
    ...mergePoll,
    ...newPoll,
  }

  poll.status = "stage"
  console.log(docRefId)
  await setDoc(doc(db, "polls-stage", docRefId), {
    ...poll
  });

  await updateDoc(doc(db, "polls-stage-ids", "polls-stage-ids"), {
    ids: arrayUnion(docRefId)
  })

  console.log("Document written with ID: ", docRefId);
  newPollReset()

  return true  
}

const publishPoll = async (poll, stageId) => {
  console.log("publish: ",stageId)
  let _stageIds = stageIds.ids
  const index = _stageIds.indexOf(stageId)
  _stageIds = _stageIds.splice(index, 1)

  const counts = {
    count_0: 0,
    count_1: 0,
    count_2: 0,
    count_3: 0,
    count_4: 0,
    count_5: 0,
    count_6: 0,
    count_7: 0,
    count_8: 0,
    count_9: 0,
  }

  const docRef = await addDoc(collection(db, "polls-live"), {
    type: poll.type,
    topic: poll.topic,
  });
  const date = Timestamp.fromDate(new Date())

  poll = {...poll, ...counts}
  poll.date = date
  poll.status = "live"

  if (poll.timeLimit == 0){
    poll.deadline = new Date()  
  } else {
    const res = getDeadlineTime(poll.timeLimit)
    poll.timeLimit = true
    poll.deadline = res.deadline
  }

  // if (poll.newThread) {
  //   const threadRef = await addDoc(collection(db, "poll-threads"),{})
  //   await setDoc(doc(db, "poll-threads", threadRef.id),{
  //     topic: poll.topic,
  //     name: poll.newThread,
  //     count: 0,
  //     pollIds: [docRef.id],
  //     description: poll.newThreadDes,
  //     type: poll.type
  //   })
  //   poll.thread = threadRef.id
  // }  
  // if (poll.thread){
  //   await updateDoc(doc(db, "poll-threads", poll.thread), {
  //     pollIds: arrayUnion(docRef.id),
  //     count: increment
  //   })    
  // }
  // delete poll['newThread']
  // delete poll['newThreadDes']

  await setDoc(doc(db, "polls-live", docRef.id),{
    ...poll
  })

  console.log("Document written with ID: ", docRef.id);  

  // await updateDoc(doc(db, "polls-latest", "polls-latest"), {
  //   ids: arrayUnion(docRef.id)
  // })

  const newEntry = {
    id: docRef.id,
    type: poll.type,
    topic: poll.topic
  }
  await updateDoc(doc(db, "polls-latest", "polls-latest"), {
    feed: arrayUnion(newEntry)
  })
  
  deleteFromStage(stageId)
  return poll.id  
}

export {
  newPoll,
  updateTitle,
  updateInfo,
  updateTopic,
  updateCountry,
  updateThumb,
  updateTimeLimit,
  threadHandler,
  threadDescription,
  uploadPoll,
  publishPoll
}