<template>
  <div class="w-20 h-20 bg-gray-300 overflow-hidden mr-2">
    <img :src="getImg()" class="img-thumbnail-app" />
  </div>
</template>

<script>
export default {
  props: ["imgurl"],
  methods: {
    getImg() {
      if (this.imgurl) {
        // console.log("img:");
        // console.log(this.imgurl);
        return this.imgurl;
      } else {
        console.log("getting logo");
        return require("../../../assets/op_icon.png");
      }
    },
  },
};
</script>

<style>
.img-thumbnail-app {
  object-fit: contain;
  object-position: 50% 50%;
  width: 50%;
  height: 50%;
}
</style>