<template>
  <div class="wrapper">
    <custom-header>
      <template v-slot:header-main>
        <div class="flex justify-between">
          <div class="flex">
            <header-element class="font-semibold px-4">New Poll</header-element>
          </div>
          <div>
            <header-element class="relative">
              <btn
                class=""
                @toggleMenuPollType="toggleMenuPollType"
                emitName="toggleMenuPollType"
                :buttonText="pollType.name"
                bold
                large
                icon="chevron-down"
              />
              <transition>
                <dropdown-menu
                  v-if="menuPollType"
                  class="absolute right-4 top-12"
                >
                  <div
                    v-for="item in pollTypes"
                    :key="item.id"
                    class="
                      py-2
                      px-2
                      border-b border-slate-200
                      hover:bg-gray-200
                      cursor-pointer
                      w-72
                    "
                    @click="selectPollType(item)"
                  >
                    <div class="flex">
                      <div class="text-xs font-semibold mb-1">
                        {{ item.name }}
                      </div>
                      <div
                        v-if="item.id == pollType.id"
                        class="pl-2 text-default"
                      >
                        <icon name="check" small />
                      </div>
                    </div>
                    <div class="text-xs text-gray-500">
                      {{ item.description }}
                    </div>
                  </div>
                </dropdown-menu>
              </transition>
            </header-element>
          </div>
        </div>
      </template>
    </custom-header>

    <div class="content container mx-auto max-w-screen-sm px-4 pt-8">
      <input-base
        label="Question"
        full
        placeholdertext="What do you want to ask?"
        emitName="updatePollTitle"
        @updatePollTitle="updatePollTitle"
      />
      <input-base
        label="Additional Info"
        full
        optional
        textbox
        placeholdertext="Add any additional info"
        emitName="updatePollInfo"
        @updatePollInfo="updatePollInfo"
      />
      <div>
        <label for="country" class="text-xs pb-1 font-medium text-gray-600"
          >Country</label
        >
        <select
          id="country"
          name="country"
          class="
            mt-1
            block
            w-full
            sm:w-3/6
            px-2
            py-2
            text-sm
            border border-gray-400
            bg-white
            sm:text-sm
            rounded-md
            mb-6
          "
          @change="selectCountry"
        >
          <!-- <option hidden disabled selected value></option> -->
          <option v-for="(country, index) in countries" :key="index">
            {{ country }}
          </option>
        </select>
      </div>

      <div class="pt- mb-8">
        <div class="text-xs pb-1 font-medium text-gray-600 mb-2">
          Thumbnail (optional)
        </div>
        <div class="w-full sm:w-1/2">
          <input-image
            :index="-1"
            @updateImg="handleUpdateThumbnail"
            type="thumb"
          />
        </div>
      </div>

      <create-standard v-if="pollType.id == 'standard'" />
      <create-binary-image v-if="pollType.id == 'binary-image'" />
      <create-binary-text v-if="pollType.id == 'binary-text'" />
      <create-score v-if="pollType.id == 'score'" />
      <create-scale v-if="pollType.id == 'scale'" />

      <div class="mt-4">
        <label for="category" class="m-0 text-xs pb-1 font-medium text-gray-600"
          >Time limit to vote</label
        >
        <select
          id="deadline"
          name="deadline"
          class="
            mt-1
            block
            w-full
            sm:w-3/6
            px-2
            py-2
            text-sm
            border border-gray-400
            bg-white
            sm:text-sm
            rounded-md
            mb-6
          "
          @change="selectDeadline"
        >
          <!-- <option hidden disabled selected value></option> -->
          <option v-for="(timeLimit, index) in timeLimits" :key="index">
            {{ timeLimit.text }}
          </option>
        </select>
      </div>

      <!-- <div class="">
        <div class="text-xs pb-1 font-medium text-gray-600 mb-2">Thread</div>
        <div class="flex flex-col items-start">
          <div class="flex items-center mb-6">
            <input
              type="radio"
              id="no-thread"
              name="drone"
              value="no-thread"
              :checked="state.threadOption == 0"
              class="mb- cursor-pointer p-4"
              @click="noThread()"
            />
            <label for="no-thread" class="text-sm ml-4">No thread</label>
          </div>
          <div class="flex items-center mb- w-full">
            <input
              type="radio"
              :checked="state.threadOption == 2"
              id="add-thread"
              name="drone"
              value="add-thread"
              class="mb-4 cursor-pointer p-4"
            />
            <select
              id="addthread"
              name="addthread"
              class="
                mt-1
                ml-4
                block
                w-full
                sm:w-3/6
                px-2
                py-2
                text-sm
                border border-gray-400
                bg-white
                sm:text-sm
                rounded-md
                mb-6
              "
              @change="addToThread"
            >
              <option selected value hidden>Add to thread</option>
              <option v-for="(thread, index) in state.threads" :key="index">
                {{ thread.name }}
              </option>
            </select>
          </div>
          <div class="flex items-center mb- w-full">
            <input
              type="radio"
              :checked="state.threadOption == 1"
              id="new-thread"
              name="drone"
              value="new-thread"
              class="mb-4 cursor-pointer p-4"
            />
            <div class="w-full sm:w-3/6">
              <input-base
                full
                placeholdertext="Name of new thread"
                emitName="createNewThread"
                @createNewThread="createNewThread"
                class="ml-4"
              />
            </div>
          </div>
          <div class="w-full" v-if="state.threadOption == 1">
            <input-base
              full
              textbox
              placeholdertext="New thread description"
              emitName="updateThreadDescription"
              @updateThreadDescription="updateThreadDescription"
            />
          </div>
        </div>
      </div> -->
    </div>

    <custom-header isfooter>
      <template v-slot:header-main>
        <div class="flex justify-end mr-4">
          <header-element class="relative mr-4">
            <btn
              class=""
              @cancelCreate="cancelCreate"
              emitName="cancelCreate"
              buttonText="Cancel"
              bold
              color="theme"
            />
          </header-element>
          <header-element class="relative">
            <btn
              class=""
              @previewPoll="previewPoll"
              emitName="previewPoll"
              buttonText="Preview"
              bold
              fill
            />
          </header-element>
        </div>
      </template>
    </custom-header>
    <modal
      v-if="showModal"
      @click="closeModal"
      :poll="getPoll()"
      :newPoll="newPoll"
    />
  </div>
</template>

<script>
import { ref, reactive, defineComponent, onMounted } from "vue";
import CustomHeader from "../components/layout/custom-header.vue";
import HeaderElement from "../components/layout/header-element.vue";
import Btn from "../components/ui/buttons/btn.vue";
import DropdownMenu from "../components/ui/dropdown/dropdown-menu.vue";
import Icon from "../components/ui/icons/icon.vue";
import CreateStandard from "../components/create-poll/create-standard.vue";
import CreateBinaryImage from "../components/create-poll/create-binary-image.vue";
import CreateBinaryText from "../components/create-poll/create-binary-text.vue";
import CreateScore from "../components/create-poll/create-score.vue";
import CreateScale from "../components/create-poll/create-scale.vue";
import Modal from "../components/layout/modal.vue";
import InputBase from "../components/ui/inputs/input-base.vue";
import InputImage from "../components/ui/inputs/input-image.vue";
import { useRouter } from "vue-router";
import { standardPoll } from "../db/new-poll/standard";
import { binaryImagePoll } from "../db/new-poll/binary-image";
import { binaryTextPoll } from "../db/new-poll/binary-text";
import { scorePoll } from "../db/new-poll/score";
import { scalePoll } from "../db/new-poll/scale";
import { topics, timeLimits, countries } from "../db/utils";
// import { pollThreads } from "../db/feed";
import {
  newPoll,
  updateTitle,
  updateInfo,
  updateThumb,
  updateTopic,
  updateCountry,
  updateTimeLimit,
  // threadHandler,
} from "../db/new-poll/new-poll";
export default defineComponent({
  components: {
    CustomHeader,
    HeaderElement,
    Btn,
    DropdownMenu,
    Icon,
    CreateStandard,
    CreateBinaryImage,
    CreateBinaryText,
    CreateScore,
    CreateScale,
    Modal,
    InputBase,
    InputImage,
  },
  setup() {
    const router = useRouter();
    const menuPollType = ref(false);
    const pollType = reactive({
      id: "standard",
      name: "Standard Poll",
      description: "Type a question and up to five response options",
    });
    const state = reactive({
      threadOption: 0,
      threads: null,
    });
    const pollTypes = [
      {
        id: "standard",
        name: "Standard Poll",
        description: "Simple text poll",
      },
      {
        id: "binary-image",
        name: "Binary - Image",
        description: "Pick between two images",
      },
      {
        id: "binary-text",
        name: "Binary - Text",
        description: "Pick between two text blocks",
      },
      {
        id: "score",
        name: "Score",
        description: "Score out of 5",
      },
      {
        id: "scale",
        name: "Scale",
        description: "Various scale options",
      },
    ];

    const toggleMenuPollType = () => {
      menuPollType.value = !menuPollType.value;
    };

    const selectPollType = (selectPoll) => {
      pollType.id = selectPoll.id;
      pollType.name = selectPoll.name;
      pollType.description = selectPoll.description;
      toggleMenuPollType();
    };

    const updatePollTitle = (title) => {
      updateTitle(title);
    };
    const updatePollInfo = (info) => {
      updateInfo(info);
    };

    const selectTopic = (event) => {
      updateTopic(event.target.value);
    };

    const selectCountry = (event) => {
      updateCountry(event.target.value);
    };

    const handleUpdateThumbnail = (index, blob) => {
      console.log("update thumbnail");
      updateThumb(blob);
    };

    const selectDeadline = (event) => {
      updateTimeLimit(event.target.value);
    };

    // const noThread = () => {
    //   state.threadOption = 1;
    //   threadHandler(false);
    // };

    // const createNewThread = (thread) => {
    //   state.threadOption = 1;
    //   threadHandler(thread, true);
    // };

    // const updateThreadDescription = (des) => {
    //   // console.log(des);
    // };

    // const addToThread = (event) => {
    //   const threadName = event.target.value;
    //   let threadId;
    //   console.log(state.threads);
    //   state.threads.forEach((thread) => {
    //     console.log(threadName == thread.name);
    //     if (thread.name == threadName) {
    //       threadId = thread.threadId;
    //     }
    //   });
    //   state.threadOption = 2;
    //   threadHandler(threadId, false);
    // };

    const previewPoll = () => {
      router.push("/createpoll/preview");
    };

    const cancelCreate = () => {
      router.push("/polls");
    };

    const getPoll = () => {
      if (pollType.id == "standard") return standardPoll;
      if (pollType.id == "binary-image") return binaryImagePoll;
      if (pollType.id == "binary-text") return binaryTextPoll;
      if (pollType.id == "score") return scorePoll;
      if (pollType.id == "scale") return scalePoll;
    };

    onMounted(async () => {
      // console.log(pollThreads);
      // state.threads = pollThreads.pollThreads;
      // console.log(state.threads);
    });

    return {
      menuPollType,
      pollType,
      pollTypes,
      toggleMenuPollType,
      selectPollType,
      updatePollTitle,
      updatePollInfo,
      newPoll,
      previewPoll,
      standardPoll,
      cancelCreate,
      getPoll,
      topics,
      countries,
      timeLimits,
      // createNewThread,
      // updateThreadD
      selectCountry,
      handleUpdateThumbnail,
      selectDeadline,
      // noThread,
      // addToThread,
      state,
    };
  },
  methods: {
    closeModal() {
      this.$router.push("/createpoll");
    },
    // getPollLink() {},
  },
  watch: {
    $route(newVal) {
      this.showModal = newVal.name == "Preview";
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
});
</script>

<style>
</style>