import { reactive } from '@vue/reactivity';
import {db, answeredPolls} from './firebase'
import { pollFeedIds } from './feed';
import router from "../router";
import { pl } from 'date-fns/locale';
// import { collection, doc, onSnapshot, orderBy, query, setDoc, getDocs, getDoc } from "firebase/firestore";

const DEFAULT_TIME = 12000
const INTERVAL = 10

let playMode = reactive({
  mode: false,
  timeRemaining: DEFAULT_TIME,
  timer: "running",
  currentPoll: null,
  previousPolls: [],
  remainingPolls: [],
  transition: false,
  pauseForStats: false,
  count: 0,
  paused: false
})

const resetPlayMode = () => {
  playMode.mode = false
  playMode.timeRemaining = DEFAULT_TIME
  playMode.timer = "running"
  playMode.currentPoll = null
  playMode.remainingPolls = []
  playMode.previousPolls = []
  playMode.allPolls = []
  playMode.transition = false
  playMode.pauseForStats = false
  playMode.count = 0
}

const enableShowStats = () => {
  return true
  // return localStorage.getItem("shownStats") == 'true'
}

const setRemainingPolls = () => {
  let unansweredPolls = []
  let allPolls = []
  pollFeedIds.ids.forEach((pollId, index) =>{
    // console.log(pollFeedIds.timestamp[index])
    if (pollId){
      if (answeredPolls.answeredPolls.indexOf(pollId) == -1){
        unansweredPolls.push(pollId)
      }
    }
    allPolls.push(pollId)
  })
  playMode.allPolls = allPolls
  playMode.remainingPolls = unansweredPolls
  // console.log(playMode.remainingPolls)
}

// let currentPollIndex = 0;
let myInterval;

const nextPoll = (remove= false, pollId) => {
  playMode.transition = true
  playMode.timeRemaining = DEFAULT_TIME  
  if (playMode.mode){
    
    pausePlayMode()
    const prevPollId = playMode.currentPoll
    const pollIndex = playMode.remainingPolls.indexOf(prevPollId)
    playMode.remainingPolls.splice(pollIndex, 1)
    
    if (!remove){
      playMode.previousPolls.push(prevPollId)
    }

    if (playMode.remainingPolls.length == 0){
      router.push("/")
      endPlayMode()
      return;    
    }
    
    const nextPollId = playMode.remainingPolls[0]  
    playMode.currentPoll = nextPollId 
    router.push("/"+ nextPollId)
  } else {
    if (playMode.allPolls.length == 0){
      setRemainingPolls()
    }
    const pollIndex = playMode.allPolls.indexOf(pollId)
    let nextPollId
    if (pollIndex == playMode.allPolls.length){
      nextPollId = playMode.allPolls[0]
    } else {
      nextPollId = playMode.allPolls[pollIndex + 1]
    }
    router.push("/"+ nextPollId)
  }
}

const prevPoll = (pollId) => {
  playMode.transition = true
  playMode.timeRemaining = DEFAULT_TIME  
  if (playMode.mode){
    
    playMode.timeRemaining = DEFAULT_TIME  
    pausePlayMode()  
    const prevPollId = playMode.previousPolls.pop()
    playMode.remainingPolls.unshift(prevPollId)
    playMode.currentPoll = prevPollId 
    router.push("/"+ prevPollId)  
    resumePlayMode()
  } else {
    if (playMode.allPolls.length == 0){
      setRemainingPolls()
    }
    const pollIndex = playMode.allPolls.indexOf(pollId)
    let nextPollId
    if (pollIndex == 0){
      nextPollId = playMode.allPolls[playMode.allPolls.length - 1]
    } else {
      nextPollId = playMode.allPolls[pollIndex - 1]
    }
    router.push("/"+ nextPollId)    
  }
}

const incrAnsweredCount = () => {
  playMode.count = playMode.count + 1
}

const pauseForStats = () => {
  pausePlayMode()
  playMode.pauseForStats = true;
}

const continueFromStats = () => {
  // console.log("continue from stats")
  // console.log(playMode)
  playMode.pauseForStats = false;
  resumePlayMode()
}



const timerHandler= () => {
  // console.log("time remaining: ", playMode.timeRemaining)
  if (playMode.timer == "running"){

    playMode.timeRemaining = playMode.timeRemaining - INTERVAL
    if (playMode.timeRemaining <= 0){
      nextPoll(false)
    }
  }
}

const transitionEnd = () => {
  playMode.transition = false
  resumePlayMode()
}

const initPlayMode = (pollId) => {
  // console.log("***** INIT PLAY MODE *****")
  playMode.mode = true;
  playMode.currentPoll = pollId
  setRemainingPolls();
  myInterval = setInterval(timerHandler, INTERVAL)
  // console.log("INTERVAL ID: ", myInterval)
}

const pausePlayMode = () => {
  // console.log("pause")
  playMode.timer = "paused"
  // playMode.timeRemaining = playMode.timeRemaining + INTERVAL
}
const resumePlayMode = () => {
  // console.log("resume")
  playMode.timer = "running"
}

const endPlayMode = () => {
  // console.log("END PLAY MODE")
  resetPlayMode()
  clearInterval(myInterval)
}

export {
  playMode,
  setRemainingPolls,
  initPlayMode,
  pausePlayMode,
  pauseForStats,
  continueFromStats,
  resumePlayMode,
  nextPoll,
  prevPoll,
  incrAnsweredCount,
  enableShowStats,
  transitionEnd,
  endPlayMode,
  DEFAULT_TIME
}