<template>
  <div class="button-container">
    <button
      class="
        flex
        py-2
        rounded-full
        text-xs
        transition-all
        duration-75
        justify-center
      "
      :disabled="disabled || disableddummy"
      :class="getClasses()"
      @click.stop
      @click="handleEmit"
    >
      <icon
        v-if="icon && iconFirst && !iconLabel"
        :name="icon"
        :class="getIconClasses()"
        :xsmall="iconSize == 'xsmall'"
        :small="iconSize == 'small'"
        :large="iconSize == 'large'"
      />
      <div :class="getTextClass">{{ buttonText }}</div>
      <div class="flex items-center">
        <icon
          v-if="icon && iconFirst"
          :name="icon"
          :class="getIconClasses()"
          :xsmall="iconSize == 'xsmall'"
          :small="iconSize == 'small'"
          :large="iconSize == 'large'"
        />
        <div v-if="iconLabel" :class="iconFirst ? 'ml-1' : 'mr-1'">
          {{ iconLabel }}
        </div>
        <icon
          v-if="icon && !iconFirst"
          :name="icon"
          :class="getIconClasses()"
          :xsmall="iconSize == 'xsmall'"
          :small="iconSize == 'small'"
          :large="iconSize == 'large'"
        />
      </div>
    </button>
  </div>
</template>

<script>
import Icon from "../icons/icon.vue";
import { getColor } from "../../../db/utils";
export default {
  components: { Icon },
  props: {
    buttonText: String,
    emitName: String,
    color: String,
    fill: Boolean,
    outlined: Boolean,
    large: Boolean,
    semibold: Boolean,
    bold: Boolean,
    icon: String,
    iconSize: String,
    iconLabel: String,
    ripple: {
      type: Boolean,
      default: false,
    },
    iconFirst: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableddummy: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEmit() {
      this.$emit(this.emitName);
    },
    getIconClasses() {
      let classString = [];
      if (this.buttonText == null) {
        classString.push("-ml-0");
      } else {
        classString.push("ml-1");
      }
      if (this.iconSize) {
        classString.push(this.iconSize);
      }
      return classString.join(" ");
    },
    getClasses() {
      let classString = [];
      let color;
      if (!this.disabled) {
        this.color ? (color = this.color) : (color = "theme");
        if (this.fill) {
          classString.push(getColor("bg", color));
          const themeColorText = ["neutral", "white"];
          if (themeColorText.includes(color)) {
            classString.push("text-theme");
          } else {
            classString.push("text-white");
          }
        } else {
          classString.push("hover:bg-gray-100");
          classString.push("text-" + color);
        }
        if (this.outlined) {
          classString.push("border border-" + color);
        } else {
          classString.push("border-0 ");
        }
        if (this.semibold) {
          classString.push("font-semibold");
        }
        if (this.large) {
          classString.push("text-sm");
        }
        if (this.ripple) {
          console.log("is rippler");
          classString.push("button-ripple");
        }
        if (this.full) {
          classString.push("w-full");
        }
      } else {
        // IF DISABLED
        if (!this.fill) {
          classString.push("text-buttondisabled");
        } else {
          classString.push("bg-gray-100");
          classString.push("text-buttondisabled");
        }
        if (this.outlined) {
          classString.push("border border-buttondisabled");
        } else {
          classString.push("border-0 ");
        }
      }
      if (this.buttonText == null) {
        classString.push("px-2");
      } else {
        classString.push("px-4");
      }
      if (this.bold) {
        classString.push("font-bold");
      }
      return classString.join(" ");
    },
    getTextClass() {
      return "ml-";
    },
  },
};
</script>

<style>
.button-ripple {
  transition-duration: 0.3s;
  position: relative;
  /* z-index: 1000; */
}
.button-ripple:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s;
  box-shadow: 0 0 5px 20px rgb(220, 220, 220);
}
.button-ripple:active:after {
  box-shadow: 0 0 0 0 rgb(220, 220, 202);
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.button-ripple-rounded {
  transition-duration: 0.3s;
  position: relative;
  /* z-index: 1000; */
}
.button-ripple-rounded:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 2px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s;
  box-shadow: 0 0 5px 20px rgb(220, 220, 220);
}
.button-ripple-rounded:active:after {
  box-shadow: 0 0 0 0 rgb(220, 220, 202);
  position: absolute;
  border-radius: 2px;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.button-ripple-unrounded {
  transition-duration: 0.3s;
  position: relative;
  /* z-index: 1000; */
}
.button-ripple-unrounded:after {
  content: "";
  display: block;
  position: absolute;
  /* border-radius: 4em; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s;
  box-shadow: 0 0 5px 20px rgb(220, 220, 220);
}
.button-ripple-unrounded:active:after {
  box-shadow: 0 0 0 0 rgb(220, 220, 202);
  position: absolute;
  /* border-radius: 4em; */
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.button-container:active {
  top: 1px;
}
</style>