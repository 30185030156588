import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getStorage, uploadBytes, getDownloadURL   } from "firebase/storage";
import { ref as FBRef }  from "firebase/storage";
import { reactive, ref} from '@vue/reactivity';
import { collection, doc, onSnapshot, orderBy, query, updateDoc, deleteDoc, Timestamp, addDoc } from "firebase/firestore";
import { stageIds } from './feed';
import { resumePlayMode, nextPoll, incrAnsweredCount } from './play';



// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyB3s2SbauKzMJcMNni2oOcaMxXtGkm4h_A',
  authDomain: 'poll-app-149.firebaseapp.com',
  projectId: 'poll-app-149',
  storageBucket: 'poll-app-149.appspot.com',
  messagingSenderId: '215021845581',
  appId: '1:215021845581:web:411845dce1bf6bb4043004',
  measurementId: 'G-DCX1ZL3B0V'
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}

// utils
const fb = firebase
const db = firebase.firestore()
const auth = firebase.auth()
// collection references
// const testCollection = db.collection('test-data')
const pollsLive = db.collection('polls-live')
const pollsStage = db.collection('polls-stage')
const RESULTS_REF = "results"
const USERS_REF = "users"
const VOTED_LOG_REF = "voted-log"
const DELETED_POLLS_REF = "deleted-polls"
const VOTES_REF = "votes"
const NAV_REF = "nav-log"
const votedLog = db.collection(VOTED_LOG_REF)
const deletedPollsLog = db.collection(DELETED_POLLS_REF)
const votes = db.collection(VOTES_REF)
// const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);
const storage = getStorage();

let uid = ref(false)
let uid_static = null

// const fetchPollsAnswered = reactive({
//   pollsAnswered: [],
// });
let answeredPolls = reactive({
  answeredPolls: []
})
let pollResponses = reactive({
  pollResponses: []
})
let deletedPolls = reactive({
  deletedPolls: []
})

let livePolls = reactive({
  livePolls: []
})

let stagePolls = reactive({
  stagePolls: []
})

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // console.log("authstatechanged")
    uid = user.uid
    uid_static = user.uid
    localStorage.setItem("uid", uid)
    if (localStorage.getItem("shownStats") === null){
      localStorage.setItem("shownStats", false)
    }
    console.log(uid)
    const unsub = onSnapshot(doc(db, VOTED_LOG_REF, uid), (doc) => {
      answeredPolls.answeredPolls = doc.data().polls
      pollResponses.pollResponses = doc.data().responses
    });
    const unsub2 = onSnapshot(doc(db, DELETED_POLLS_REF, uid), (doc) => {
      deletedPolls.deletedPolls = doc.data().polls
    });
    if (!localStorage.getItem("deletedRefSet")){
      db.collection(DELETED_POLLS_REF).doc(uid).set({'polls':[]}).then(()=> {
        localStorage.setItem("deletedRefSet", "true")
      })
    }
  } else {
    firebase.auth().signInAnonymously()
    .then((user) => {
      uid = user.user.uid
      localStorage.setItem("uid", uid)
      
      // console.log("signed in anon", user.user.uid)
      db.collection(VOTED_LOG_REF).doc(uid).set({'polls':[]})
      .then(() => {
        // console.log("Answered polls log created");
        const unsub = onSnapshot(doc(db, VOTED_LOG_REF, uid), (doc) => {
          answeredPolls.answeredPolls = doc.data().polls

        });
      })
      db.collection(NAV_REF).doc(uid).set({'nav-log':[]})
      .then(() => {
        // console.log("Answered polls log created");
        // const unsub = onSnapshot(doc(db, VOTED_LOG_REF, uid), (doc) => {
        //   answeredPolls.answeredPolls = doc.data().polls

        // });
      })
      db.collection(DELETED_POLLS_REF).doc(uid).set({'polls':[]}).then(()=> {
        localStorage.setItem("deletedRefSet", "true")
      })
    })
    .catch((error) => {
      console.log(error)
      // var errorCode = error.code;
      // var errorMessage = error.message;
    });
  }
});

const signIn = (email, pwd) => {
  firebase.auth().signInWithEmailAndPassword(email, pwd).catch(function(error) {
    // console.log(error.code);
    // console.log(error.message);
 });
}

const fetchPolls = () => {
  // console.log("fetch polls");
  const pollsRef = pollsLive.orderBy("date", "desc");
  const q = query(collection(db,'polls-live'), orderBy("date", "desc"))
  let fetchedPolls = [];
  let unsub = onSnapshot(q, (querySnapshot) => {
    fetchedPolls = [];
    querySnapshot.forEach((doc) => {
      let item = doc.data();
      item["id"] = doc.id;
      // console.log(doc.id)
      fetchedPolls.push(item);
    });
    // fetchedPolls = new Array(10).fill(fetchedPolls[0]);
    livePolls.livePolls = fetchedPolls;
    // console.log("fetched polls");
  });
};

const fetchStage = () => {
  console.log("fetch stage");
  const pollsRef = pollsLive.orderBy("date", "desc");
  const q = query(collection(db,'polls-stage'), orderBy("date", "desc"))
  let fetchedPolls = [];
  let unsub = onSnapshot(q, (querySnapshot) => {
    fetchedPolls = [];
    querySnapshot.forEach((doc) => {
      let item = doc.data();
      item["id"] = doc.id;
      // console.log(doc.id)
      fetchedPolls.push(item);
    });
    // fetchedPolls = new Array(10).fill(fetchedPolls[0]);
    stagePolls.stagePolls = fetchedPolls;
  });
};

const deleteFromStage = async (stageId) => {
  let _stageIds = stageIds.ids
  const index = _stageIds.indexOf(stageId)
  _stageIds = _stageIds.splice(index, 1)
  await updateDoc(doc(db, "polls-stage-ids", "polls-stage-ids"), {
    ids: _stageIds
  })  
  await deleteDoc(doc(db, "polls-stage", stageId))
  return true
}

const getPoll = (id) => {
  let docRef = pollsLive.doc(id);
  let res = {poll: null, loading: false}
  return docRef
  .get()
  .then((doc) => {
    if (doc.exists) {
      res.poll = doc.data()
    }
    return res 
    })
    .catch((error) => {
      // console.log("Error getting document:", error);
      return res
    });
  }
  
const getPollResult = (id) => {
  let docRef = pollsLive.doc(id).collection(RESULTS_REF).doc(id);
  let res = {result: null,  loading: false}
  return docRef
  .get()
  .then((doc) => {
    if (doc.exists) {
      res['result'] = doc.data()
      // console.log(doc.data())
      // console.log(res)
      // console.log(res['result']['options'])
    }
    return res 
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return res
    });
  }
  
const uploadImg = async (docRef, blob) => {
  const imgRef = FBRef(storage, "/poll-images/"+docRef);
  // console.log(img.getDownloadURL())
  console.log(imgRef)
  console.log(docRef)
  return uploadBytes(imgRef, blob).then((snapshot) => {
    console.log("step 1")
    return
  })
  .then(() => {
    console.log("step 2")
    return getDownloadURL(imgRef)
  })
}

const getShowResult = (pollId) => {
  let index = answeredPolls.answeredPolls.indexOf(pollId)
  // console.log(pollId, index)
  return index == -1? false : true
}

const submitVote = (pollId, optionIndex) => {
  // console.log("vote: ", optionIndex)
  let batch = db.batch()
  let doc = pollsLive.doc(pollId)
  const keyString = "count_" + optionIndex
  batch.update(doc, {
    totalVotes: increment,  
    [keyString]: increment
  });  
  doc = votedLog.doc(uid)
  const votedObj = {
    pollId: pollId,
    index: optionIndex
  }
  batch.update(doc, {polls: arrayUnion(pollId), responses: arrayUnion(votedObj)})
  
  doc = votes.doc("votes")
  let voteObj = {
    "uid": uid,
    "pollid": pollId,
    "index": optionIndex,
    "time": Timestamp.fromDate(new Date()),
    "country": localStorage.getItem("location") || false
  }
  batch.update(doc, {votes: arrayUnion(voteObj)})
  incrAnsweredCount()
  setTimeout(() => {
    resumePlayMode()
  }, 100)
  return batch.commit()
}


const navLog = (page) => {
  if (uid_static){
    let doc = db.collection(NAV_REF).doc(uid_static)
    let navObj = {
      "page": page,
      "time": Timestamp.fromDate(new Date())
    }
    doc.update({'nav-log': arrayUnion(navObj)})
  }
}

const submitPass = (pollId, gotoNext = true) => {
  let batch = db.batch()
  let doc = deletedPollsLog.doc(uid)

  batch.update(doc, {polls: arrayUnion(pollId)})
  if (gotoNext){
    nextPoll(true)

  }
  return batch.commit()
}

const sendSuggestion = (text) => {
  addDoc(collection(db, "suggestions"), {
    uid: uid,
    text: text, 
    date: Timestamp.fromDate(new Date())
  });
  
}

const sendFeedback = (text) => {
  addDoc(collection(db, "feedback"), {
    uid: uid,
    text: text, 
    date: Timestamp.fromDate(new Date())
  });
}


export {
  fb,
  auth,
  signIn, 
  uid,
  votedLog,
  VOTED_LOG_REF,
  answeredPolls,
  pollResponses,
  deletedPolls,
  getShowResult,
  db,
  pollsLive,
  pollsStage,
  getPoll,
  getPollResult,
  submitVote,
  submitPass,
  uploadImg,
  USERS_REF,
  increment,
  decrement,
  arrayUnion,
  livePolls,
  fetchPolls,
  stagePolls,
  fetchStage,
  deleteFromStage,
  sendSuggestion,
  sendFeedback,
  navLog
}