<template>
  <div class="" :class="getContainerSize()">
    <input
      type="file"
      accept="image/*"
      :name="'image-' + index"
      :id="'file-' + index"
      @change="handleAddImage"
      style="display: none"
    />
    <label
      :for="'file-' + index"
      class="
        cursor-pointer
        flex
        justify-center
        content-center
        items-center
        h-full
      "
      :class="{ 'ml-2': index == 1 }"
    >
      <img
        v-if="getImage(index)"
        class=""
        :src="getImage(index)"
        :class="getImgSize()"
      />
      <div
        v-else
        class="
          cursor-pointer
          border-2 border-gray-300 border-dashed
          flex
          justify-center
          items-center
        "
        :class="getInputSize()"
      >
        <div
          class="
            flex flex-col
            justify-center
            items-center
            text-sm text-gray-500
          "
        >
          <div class=""><icon name="photograph" large /></div>
          <div class="">Image {{ index + 1 }}</div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { options as BinaryImageOptions } from "../../../db/new-poll/binary-image";
import { options as StandardOptions } from "../../../db/new-poll/standard";
import { scorePoll } from "../../../db/new-poll/score";
import { scalePoll } from "../../../db/new-poll/scale";
import { newPoll } from "../../../db/new-poll/new-poll";
import { optionImgs } from "../../../db/new-poll/standard";
import Icon from "../../ui/icons/icon.vue";
import standardDetailVue from "../../poll/standard/standard-detail.vue";
// const imgURL = ref(false);
// const imgBlob = ref(false);
const state = reactive({
  imgURL: null,
  imgBlob: null,
});
export default {
  props: ["index", "type", "avatar"],
  components: {
    Icon,
  },
  setup(props, context) {
    function handleAddImage(event) {
      const blob = event.target.files[0];
      // imgBlob.value = blob;
      // imgURL.value = URL.createObjectURL(blob);
      state.imgBlob = blob;
      state.imgURL = URL.createObjectURL(blob);
      const index = props.index;
      context.emit("updateImg", index, blob, URL.createObjectURL(blob));
      // updateImg(index, blob);
      // context.emit("updateImg", imgURL.value, imgBlob.value);
    }
    const getContainerSize = () => {
      if (props.avatar) {
        return "";
      } else {
        return "w-full h-full";
      }
    };
    const getInputSize = () => {
      if (props.avatar) {
        return "h-24 w-24 rounded-full";
      } else {
        return "w-full h-36 sm:h-64";
      }
    };
    const getImgSize = () => {
      if (props.avatar) {
        return "max-h-24 max-w-24 min-h-24 min-w-24 rounded-full";
      } else {
        return "w-full h-full ";
      }
    };
    const getImage = (index) => {
      if (props.type == "thumb") {
        return newPoll.thumbURL;
      }
      if (props.type == "binary-image") {
        return BinaryImageOptions[index].imgURL;
      }
      if (props.type == "score") {
        return scorePoll.imgURL;
      }
      if (props.type == "scale") {
        return scalePoll.imgURL;
      }
      if (props.type == "option") {
        console.log("option image");
        if (StandardOptions[index]) {
          console.log(StandardOptions);
          return StandardOptions[index].imgURL;
        } else {
          return null;
        }
      }
    };

    return {
      // imgURL,
      state,
      handleAddImage,
      getImage,
      getContainerSize,
      getInputSize,
      getImgSize,
    };
  },
};
</script>

<style lang="postcss">
/* .add-image-outer {
  height: 80px;
  width: 107px;
} */
/* .add-image-container {
  @apply appearance-none text-sm block  text-gray-400 leading-tight focus:outline-none focus:bg-white;
} */
</style>