<template>
  <div
    class="header flex bg-white h-12 border-slate-400"
    :class="isfooter ? 'border-t' : 'border-b'"
  >
    <div class="header-l display hidden md:block bg-white">
      <slot name="header-l"></slot>
    </div>
    <div class="header-main container mx-auto max-w-screen-sm bg-white">
      <slot name="header-main"></slot>
    </div>
    <div class="header-r hidden md:block bg-white">
      <slot name="header-r"></slot>
    </div>
  </div>
</template> 

<script>
export default {
  props: {
    isfooter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>