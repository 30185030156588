<template>
  <div class="font-bold pr-3 pb-1 textmd">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style>
/*  */
</style>