<template>
  <div class="pt-">
    <div>
      <label for="category" class="text-xs pb-1 font-medium text-gray-600"
        >Scale type</label
      >
      <select
        id="category"
        name="category"
        class="
          mt-1
          block
          w-full
          sm:w-3/6
          px-2
          py-2
          text-sm
          border border-gray-400
          bg-white
          sm:text-sm
          rounded-md
          mb-6
        "
        @change="selectScaleType"
      >
        <option hidden disabled selected value></option>
        <option v-for="(type, index) in scaleTypes" :key="index">
          {{ type.label }}
        </option>
      </select>
    </div>
    <div class="text-xs pb-1 font-medium text-gray-600 mb-2">
      Image (optional)
    </div>
    <div class="w-full sm:w-1/2">
      <input-image :index="0" @updateImg="handleUpdateImg" type="scale" />
      <!-- <input-base
          placeholdertext="Caption 2 (optional)"
          full
          emitName="updateCaption2"
          @updateCaption2="updateCaption2"
          class="ml-2 -mt-"
        /> -->
    </div>
  </div>
</template>

<script>
import InputImage from "../ui/inputs/input-image.vue";
import {
  updateImg,
  options,
  scaleTypes,
  changeScaleType,
} from "../../db/new-poll/scale";

export default {
  components: {
    InputImage,
  },
  setup() {
    const handleUpdateImg = (index, blob) => {
      updateImg(index, blob);
    };
    const selectScaleType = (event) => {
      changeScaleType(event.target.value);
    };
    return {
      options,
      handleUpdateImg,
      selectScaleType,
      scaleTypes,
    };
  },
};
</script>

<style>
</style>