import { reactive } from '@vue/reactivity';
import { db, uid, uploadImg, votedLog, pollsLive, increment, arrayUnion } from '../firebase';
import {  collection, addDoc } from "firebase/firestore";
import {  uploadPoll as uploadNewPoll } from './new-poll';

const newPollOptionDefault = {
  index: 0,
  type: 'default',
  text: null,
  count: 0
}

let options = reactive([newPollOptionDefault])
let optionImgs = reactive([
])

let standardPoll = reactive({
  options: options,
  type: "standard",
  status: "preview",
})

const updateImg = (imgURL, blob) => {
  standardPoll.imgURL = imgURL
  standardPoll.blob = blob
}

const updateOptionImg = (imgURL, blob, index) => {
  // let newObj = {
  //   imgURL: imgURL,
  //   blob: blob,
  //   index: index
  // }
  console.log("option ", index, imgURL)
  options[index].imgURL = imgURL
  options[index].blob = blob
  console.log(options)
  console.log(standardPoll.options)
}

const addOption = () => {
  const count = options.length
  let newOption = {...newPollOptionDefault}
  newOption.index = count
  options.push(newOption)
}

const deleteOption = (index) => {
  options.splice(index, 1)
  options.forEach((item, index) => {
    item.index = index
  })
}

const updateOptionText = (index, text) => {
  options[index].text = text
}

const updateOptionType = (index, type) => {
  options[index].type = type
}

const uploadImages = async (docRef, count) => {
  let imgRef
  for (let i = 0; i < count; i++){
    console.log(standardPoll.options[i])
      if (standardPoll.options[i].blob){
        imgRef = await uploadImg(docRef.id +"_" + i, standardPoll.options[i].blob)
        delete standardPoll.options[i]['blob']
        standardPoll.options[i]['optionImg'] = imgRef
      }
  }
}

const uploadPoll = async () => {
  // const docRef = getDocRef();
  const docRef = await addDoc(collection(db, "polls-stage"), {});
  let imgRef = null
  if (standardPoll.blob){
    imgRef = await uploadImg(docRef.id, standardPoll.blob)
  }
  const count = options.length
  console.log("iters", count)
  await uploadImages(docRef, count)
  console.log(standardPoll)
  uploadNewPoll(standardPoll, docRef.id)
}

export {
  standardPoll,
  options,
  optionImgs,
  updateImg,
  addOption,
  deleteOption,
  updateOptionText,
  updateOptionImg,
  updateOptionType,
  uploadPoll,
  // deleteFromStage
}