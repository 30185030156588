<template>
  <div class="pt-">
    <input-label label="Options" />
    <div class="flex">
      <div class="w-1/2">
        <input-image
          :index="0"
          @updateImg="handleUpdateImg"
          type="binary-image"
        />
        <!-- <input-base
          placeholdertext="Caption 1 (optional)"
          full
          emitName="updateCaption1"
          @updateCaption1="updateCaption1"
          class="-mt-"
        /> -->
      </div>

      <div class="w-1/2">
        <input-image
          :index="1"
          @updateImg="handleUpdateImg"
          type="binary-image"
        />
        <!-- <input-base
          placeholdertext="Caption 2 (optional)"
          full
          emitName="updateCaption2"
          @updateCaption2="updateCaption2"
          class="ml-2 -mt-"
        /> -->
      </div>
    </div>
    <div class="flex">
      <div class="w-1/2">
        <input-base
          placeholdertext="Caption 1 (optional)"
          full
          emitName="updateCaption1"
          @updateCaption1="updateCaption1"
          class="-mt-"
        />
      </div>

      <div class="w-1/2">
        <input-base
          placeholdertext="Caption 2 (optional)"
          full
          emitName="updateCaption2"
          @updateCaption2="updateCaption2"
          class="ml-2 -mt-"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputBase from "../ui/inputs/input-base.vue";
import InputImage from "../ui/inputs/input-image.vue";
import InputLabel from "../ui/inputs/input-label.vue";
import { topics, timeLimits } from "../../db/utils";
// import AddThumbnail from "../ui/inputs/add-thumbnail.vue";
// import Btn from "../ui/buttons/btn.vue";
// import OptionDefault from "./option-default.vue";
// import DropdownMenu from "../components/ui/dropdown/dropdown-menu.vue";
import {
  updateImg,
  updateOptionText,
  options,
} from "../../db/new-poll/binary-image";
export default {
  components: {
    InputBase,
    InputImage,
    InputLabel,
  },
  setup() {
    const handleUpdateImg = (index, blob) => {
      console.log("update poll img");
      console.log(index, blob);
      updateImg(index, blob);
    };

    const updateCaption1 = (text) => {
      updateOptionText(0, text);
    };

    const updateCaption2 = (text) => {
      updateOptionText(1, text);
    };

    return {
      options,
      handleUpdateImg,
      updateCaption1,
      updateCaption2,
      topics,
      timeLimits,
    };
  },
};
</script>

<style>
</style>