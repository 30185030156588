<template>
  <button
    type="button"
    class="
      bg-white
      rounded-full
      p-2
      inline-flex
      items-center
      justify-center
      text-gray-400
      font-light
      hover:text-gray-500 hover:bg-gray-100
    "
    @click="$emit('clickClose')"
  >
    <icon name="close" />
  </button>
</template>

<script>
import Icon from "../icons/icon.vue";
export default {
  components: {
    Icon,
  },
};
</script>

<style>
</style>