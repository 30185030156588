<template>
  <div class="flex -mt-2 mb-4 items-center">
    <input-image
      :index="optionIndex"
      @updateImg="handleUpdateOptionImg"
      type="option"
      :avatar="true"
    />
    <input-base
      full
      :placeholdertext="'Option ' + (optionIndex + 1)"
      class="w-full mt-4 ml-2"
      emitName="optionText"
      @optionText="optionText"
    />
    <div class="relative">
      <btn
        class="ml-4 -mt-"
        @toggleMenuOptionType="toggleMenuOptionType"
        emitName="toggleMenuOptionType"
        fill
        bold
        :color="optionType.id"
        icon="chevron-down"
        iconSize="small"
      />
      <transition>
        <dropdown-menu v-if="menuOptionType" class="absolute -right-2 top-8">
          <div class="flex">
            <div
              v-for="item in optionTypes"
              :key="item.id"
              class="py-2 px-2 hover:bg-gray-200 cursor-pointer"
              @click="selectOptionType(item)"
            >
              <div
                class="rounded-full w-8 h-8"
                :class="getColor('bg', item.id)"
              ></div>
            </div>
          </div>
        </dropdown-menu>
      </transition>
    </div>
    <btn
      v-if="!fixed"
      class="ml-2 -mt-"
      bold
      icon="trash"
      color="buttongrey"
      large
      @deleteOptionHandler="deleteOptionHandler"
      emitName="deleteOptionHandler"
    />
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import InputBase from "../ui/inputs/input-base.vue";
import Btn from "../ui/buttons/btn.vue";
import DropdownMenu from "../ui/dropdown/dropdown-menu.vue";
import {
  deleteOption,
  updateOptionText as updateTextStandard,
  updateOptionType as updateTypeStandard,
  updateOptionImg,
} from "../../db/new-poll/standard";
import {
  updateOptionText as updateTextBinary,
  updateOptionType as updateTypeBinary,
} from "../../db/new-poll/binary-text";
import { getColor } from "../../db/utils";
import InputImage from "../ui/inputs/input-image.vue";
export default {
  props: {
    optionIndex: Number,
    withLabel: {
      type: Boolean,
      default: false,
    },
    live: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    pollType: {
      type: String,
      default: "standard",
    },
  },
  components: {
    InputBase,
    Btn,
    DropdownMenu,
    InputImage,
  },
  setup(props) {
    const menuOptionType = ref(false);
    const optionType = reactive({
      id: "default",
      name: "Default",
    });
    const optionTypes = [
      {
        id: "default",
        name: "Default",
      },
      {
        id: "positive",
        name: "Positive",
      },
      {
        id: "amber",
        name: "Amber",
      },
      {
        id: "amberred",
        name: "AmberRed",
      },
      {
        id: "negative",
        name: "Negative",
      },
      {
        id: "neutral",
        name: "Neutral",
      },
      {
        id: "offblack",
        name: "OffBlack",
      },
    ];

    const selectOptionType = (selectedOptionType) => {
      optionType.id = selectedOptionType.id;
      optionType.name = selectedOptionType.name;
      menuOptionType.value = false;
      if (props.pollType == "standard") {
        updateTypeStandard(props.optionIndex, selectedOptionType.id);
      }
      if (props.pollType == "binary-text") {
        updateTypeBinary(props.optionIndex, selectedOptionType.id);
      }
    };

    const toggleMenuOptionType = () => {
      menuOptionType.value = !menuOptionType.value;
    };

    const deleteOptionHandler = () => {
      deleteOption(props.optionIndex);
    };

    const optionText = (text) => {
      if (props.pollType == "standard") {
        updateTextStandard(props.optionIndex, text);
      }
      if (props.pollType == "binary-text") {
        updateTextBinary(props.optionIndex, text);
      }
    };

    const handleUpdateOptionImg = (index, blob, imgURL) => {
      console.log(index);
      console.log(blob);
      console.log(imgURL);
      updateOptionImg(imgURL, blob, props.optionIndex);
    };

    return {
      toggleMenuOptionType,
      optionType,
      optionTypes,
      selectOptionType,
      menuOptionType,
      deleteOptionHandler,
      optionText,
      getColor,
      handleUpdateOptionImg,
    };
  },
};
</script>

<style>
</style>