import { reactive } from '@vue/reactivity';
import { db } from '../firebase';
import {  collection, addDoc } from "firebase/firestore";
import {  uploadPoll as uploadNewPoll } from './new-poll';

const newPollOption_0 = {
  index: 0,
  type: 'default',
  text: null,
  count: 0
}

const newPollOption_1 = {
  index: 1,
  type: 'default',
  text: null,
  count: 0
}

let options = reactive([newPollOption_0, newPollOption_1])

let binaryTextPoll = reactive({
  options: options,
  status: "preview",
  type: "binary-text",
})


const updateOptionText = (index, text) => {
  options[index].text = text
}

const updateOptionType = (index, type) => {
  console.log("binary type")
  options[index].type = type
}

const uploadPoll = async () => {
  const docRef = await addDoc(collection(db, "polls-stage"), {});

  uploadNewPoll(binaryTextPoll, docRef.id)
}

export {
  binaryTextPoll,
  options,
  updateOptionText,
  updateOptionType,
  uploadPoll,
}