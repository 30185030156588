import { reactive } from '@vue/reactivity';
import { db, uid, uploadImg, votedLog, pollsLive, increment, arrayUnion } from '../firebase';
import {  collection, addDoc } from "firebase/firestore";
import {  uploadPoll as uploadNewPoll } from './new-poll';

const scaleTypes = [
  {
    type: "likely-unlikely",
    label: "Likely/unlikely"
  },
  {
    type: "agree-disagree",
    label: "Agree/disagree"
  },
  {
    type: "high-low",
    label: "High/low"
  },
  {
    type: "better-worse",
    label: "Better/worse"
  },
  {
    type: "always-never",
    label: "Always/never"
  },
  {
    type: "good-bad",
    label: "Good/bad"
  },

]

const optionTypes = {

  "Good/bad": [ 
    {  
      index: 0,
      type: 'negative',
      text: 'Very bad',
      count: 0
    }, 
    {  
      index: 1,
      type: 'negativefaded',
      text: 'Quite bad',
      count: 0
    }, 
    {  
      index: 2,
      type: 'amberfaded',
      text: 'Neutral',
      count: 0
    }, 
    {  
      index: 3,
      type: 'positivefaded',
      text: 'Quite good',
      count: 0
    }, 
    {  
      index: 4,
      type: 'positive',
      text: 'Very good',
      count: 0
    },
  ],
  "Better/worse": [ 
    {  
      index: 0,
      type: 'negative',
      text: 'Much worse',
      count: 0
    }, 
    {  
      index: 1,
      type: 'negativefaded',
      text: 'Worse',
      count: 0
    }, 
    {  
      index: 2,
      type: 'amberfaded',
      text: 'Same',
      count: 0
    }, 
    {  
      index: 3,
      type: 'positivefaded',
      text: 'Better',
      count: 0
    }, 
    {  
      index: 4,
      type: 'positive',
      text: 'Much better',
      count: 0
    },
  ],

  "Likely/unlikely": [ 
    {  
      index: 0,
      type: 'negative',
      text: 'Very unlikely',
      count: 0
    }, 
    {  
      index: 1,
      type: 'negativefaded',
      text: 'Quite unlikely',
      count: 0
    }, 
    {  
      index: 2,
      type: 'amberfaded',
      text: 'About 50/50',
      count: 0
    }, 
    {  
      index: 3,
      type: 'positivefaded',
      text: 'Quite likely',
      count: 0
    }, 
    {  
      index: 4,
      type: 'positive',
      text: 'Very likely',
      count: 0
    },
  ],
  "Agree/disagree": [ 
    {  
      index: 0,
      type: 'negative',
      text: 'Strongly disagree',
      count: 0
    }, 
    {  
      index: 1,
      type: 'negativefaded',
      text: 'Mostly disagree',
      count: 0
    }, 
    {  
      index: 2,
      type: 'amberfaded',
      text: 'Neutral',
      count: 0
    }, 
    {  
      index: 3,
      type: 'positivefaded',
      text: 'Mostly agree',
      count: 0
    }, 
    {  
      index: 4,
      type: 'positive',
      text: 'Strongly agree',
      count: 0
    },
  ],
  "High/low": [ 
    {  
      index: 0,
      type: 'negative',
      text: 'Much lower',
      count: 0
    }, 
    {  
      index: 1,
      type: 'negativefaded',
      text: 'Lower',
      count: 0
    }, 
    {  
      index: 2,
      type: 'white',
      text: 'About the same',
      count: 0
    }, 
    {  
      index: 3,
      type: 'positivefaded',
      text: 'Higher',
      count: 0
    }, 
    {  
      index: 4,
      type: 'positive',
      text: 'Much higher',
      count: 0
    },
  ],
  "Always/never": [ 
    {  
      index: 0,
      type: 'theme',
      text: 'Never',
      count: 0
    }, 
    {  
      index: 1,
      type: 'theme',
      text: 'Rarely',
      count: 0
    }, 
    {  
      index: 2,
      type: 'theme',
      text: 'Sometimes',
      count: 0
    }, 
    {  
      index: 3,
      type: 'theme',
      text: 'Mostly',
      count: 0
    }, 
    {  
      index: 4,
      type: 'theme',
      text: 'Always',
      count: 0
    },
  ],
}

let options = reactive()

let scalePoll = reactive({
  options: options,
  blob: null,
  imgURL: null,
  type: "scale",
  status: "preview",
})

const changeScaleType = (type) => {
  options = optionTypes[type]
  scalePoll.options = optionTypes[type]
  // console.log(type.toLowerCase().replace("/", "-"))
}

const updateImg = (imgURL, blob) => {
  scalePoll.imgURL = URL.createObjectURL(blob);
  scalePoll.blob = blob
}


const uploadPoll = async () => {
  // const docRef = getDocRef();
  const docRef = await addDoc(collection(db, "polls-stage"), {});
  if (scalePoll.blob){
    await uploadImg(docRef.id + "_0", scalePoll.blob).then(res => {
      scalePoll.imgURL = res
    })  
  }
  uploadNewPoll(scalePoll, docRef.id)
}

export {
  scalePoll,
  scaleTypes,
  changeScaleType,
  options,
  updateImg,
  uploadPoll,
}