<template>
  <div class="w-full flex flex-col items-center mx-2">
    <div class="flex justify-between mb-1">
      <div class="text-xs pt-1" :class="getTextClasss()">
        {{ getWidthStr() }}
      </div>
    </div>
    <div class="w-4 flex h-24 items-end bg-gray-100">
      <div
        :style="getWidthStyle"
        class="
          shadow
          text-xs
          leading-none
          pb-
          text-center text-white
          transition-height transition-slowest
          ease
          w-full
        "
        :class="getBarClass()"
      ></div>
    </div>
    <div
      class="w-full text-xs pt-1 text-center border-t border-gray-200"
      :class="getTextClasss()"
    >
      <div class="pt-">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import { getColor } from "../../../db/utils";
export default {
  props: ["text", "count", "totalVotes", "type", "id", "isTopResult"],
  computed: {
    getWidthStyle: function () {
      const obj = { height: `${this.getWidthStr()}` };
      return obj;
    },
  },
  methods: {
    getWidthStr() {
      const pct = Math.round((this.count / this.totalVotes) * 1000) / 10;
      return `${pct}%`;
    },
    getBarClass() {
      let isFaded = false;
      return getColor("bg", this.type, isFaded);
    },
    getTextClasss() {
      if (this.isTopResult) {
        return "font-semibold";
      } else {
        return "text-gray-500";
      }
    },
  },
};
</script>

<style>
.rotated {
  transform: rotate(90);
}
</style>