import {db, navLog} from './firebase'
import { collection, doc, onSnapshot, orderBy, query, setDoc, getDocs, getDoc } from "firebase/firestore";
import { reactive } from '@vue/reactivity';
import { setRemainingPolls } from "./play";
import { isToday } from "../db/utils";
import axios from 'axios'
import VueAxios from 'vue-axios'

const _LATEST = "latest"
const _THREADS = "threads"
const pollsLatest= `polls-${_LATEST}`
const pollThreadsList = `poll-${_THREADS}`
const _POLLS_STAGE = "polls-stage-ids"

const pollFeedSelected = pollsLatest
// const category = false


let playMode = reactive({
  playMode: true
})

const setPlayMode = (mode = true) => {
  playMode.playMode = mode
}

let pollFeedIds = reactive({
  ids: [],
  types: [],
  topics: [],
  timestamp: []
})

let todayPolls = reactive({
  ids: [],
  types: [],
  topics: [],
})

let pollThreads = reactive ({
  pollThreads: []
})

let stageIds = reactive({
  ids: []
})



const getPollFeedIdsHandler = async () => {
  const country = localStorage.getItem('location')
  pollFeedIds.ids = []
  pollFeedIds.type = []
  pollFeedIds.topics = []
  getDoc(doc(db, pollFeedSelected, pollFeedSelected)).then((res) => {
    let feed = res.data()['feed']
    for (const item of feed){
      if (!item.country || (item.country == country)){
        pollFeedIds.ids.unshift(item.id)
        pollFeedIds.types.unshift(item.type)
        pollFeedIds.topics.unshift(item.topic)
      }
    }
    setRemainingPolls()
    navLog("Polls fetched")
  })
  console.log("polls fetched")
 }
 
const GET_COUNTRY = true
const getPollFeedIds = async () => {
  if ((localStorage.getItem("location") == null) && GET_COUNTRY){
    console.log("fetching loc...")
    axios.get('https://api64.ipify.org?format=json').then(response => {
      axios.get('https://geo.ipify.org/api/v2/country?apiKey=at_LcDdHWZmIhZdYtg0TfseL11QOgAAI&ipAddress='+response.data['ip']).then(loc => {
        localStorage.setItem('location', loc.data.location.country)
        getPollFeedIdsHandler()
      })
    })
  } else {
    getPollFeedIdsHandler()
  }
}


const getStageIds = async () => {
  console.log("getting stage")
  getDoc(doc(db, _POLLS_STAGE, _POLLS_STAGE)).then((res) => {
    stageIds.ids = res.data()['ids'].reverse()
    console.log(res.data()['ids'])
  })
}

export {
  getPollFeedIds,
  pollFeedIds,
  stageIds,
  getStageIds,
  // getThreads,
  pollThreads,
  playMode,
  setPlayMode
}