<template>
  <div
    class="border border-slate-500 rounded shadow-md bg-white z-50"
    style="max-height: 360px; overflow-y: auto"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>