<template>
  <div class="min-w-sm max-w-sm mx-auto max-h-screen min-h-screen relative">
    <div class="invisible lg:visible fixed left-0 top-12">
      <div class="pl-4 pr-8 pt-4 max-w-xs text-sm text-gray-400">
        We've gone for a smartphone focused design first so there's not much to
        see here yet!<br /><br />We're working on it!
      </div>
    </div>
    <div
      class="pt-12 pb-8"
      v-if="!state.showAbout && !state.showPollDetail && !state.showStats"
    >
      <div class="flex justify-center mt-12" v-if="pollFeedIds.ids.length == 0">
        <splash />
      </div>
      <div v-else class="">
        <div
          :to="'/' + pollId"
          class="w-full"
          v-for="(pollId, index) in pollFeedIds.ids"
          :key="pollId"
        >
          <poll-item
            :pollId="pollId"
            @click="setPoll(pollId)"
            :pollType="pollFeedIds.types[index]"
            pollStatus="live"
            :showPoll="showPoll(pollId)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="
        state.showPollDetail && state.selectedPoll && !playMode.pauseForStats
      "
      class="max-h-screen min-h-screen pt-12 bg-white"
    >
      <div class="max-w-sm min-w-sm mx-auto bg-white">
        <poll-detail :poll="state.selectedPoll" @clickClose="clickClose" />
      </div>
    </div>

    <div
      v-if="state.showAbout"
      class="fixed top-12 left-0 right-0 bottom-0 bg-white"
    >
      <div class="max-w-sm min-w-sm mx-auto">
        <transition>
          <about
            v-if="state.showAbout"
            class="fixed inset-0"
            @closeAbout="closeAbout"
          />
        </transition>
      </div>
    </div>

    <div
      v-if="state.showStats"
      class="fixed top-12 left-0 right-0 bottom-0 bg-white"
    >
      <div class="max-w-sm min-w-sm mx-auto">
        <transition>
          <stats
            v-if="state.showStats"
            class="fixed inset-0"
            @closeStats="closeStats"
          />
        </transition>
      </div>
    </div>

    <div
      v-if="playMode.pauseForStats"
      class="fixed top-12 left-0 right-0 bottom-0 bg-white"
    >
      <div class="max-w-sm min-w-sm mx-auto">
        <transition>
          <stats
            class="fixed inset-0"
            @closeStats="closeStats"
            :showContinue="true"
          />
        </transition>
      </div>
    </div>
    <!-- HEADER -->
    <div class="z-100 shadow fixed top-0 left-0 right-0 h-12">
      <div class="bg-theme">
        <div class="h-12 max-w-sm mx-auto grid grid-cols-3">
          <div>
            <div
              v-if="state.showPollDetail && !state.showStats"
              class="mr-2 flex h-full items-center"
            >
              <!-- <btn
                icon="chevron-left"
                :bold="true"
                color="white"
                @clickClose="clickClose"
                emitName="clickClose"
              /> -->
              <btn
                class=""
                icon="chevron-left"
                iconLabel="ALL POLLS"
                :iconFirst="true"
                :bold="true"
                color="white"
                @clickClose="clickClose"
                emitName="clickClose"
              />
            </div>
          </div>

          <div class="flex items-center justify-between ml-2 sm:ml-0">
            <div class="flex items-center cursor-pointer mr-4">
              <img
                class="h-8 rounded-full"
                src="@/assets/op_icon.png"
                alt=""
                @click="showAbout()"
              />
              <img
                class="h-8 rounded-full ml-2"
                src="@/assets/yopolly.png"
                alt=""
                @click="showAbout()"
              />
            </div>
          </div>

          <div class="flex h-full items-center justify-end">
            <div v-if="state.showAbout" class="mr-2">
              <btn
                icon="close"
                color="white"
                iconSize="medium"
                @clickClose="closeAbout"
                emitName="clickClose"
              />
            </div>
            <div v-if="state.showStats" class="mr-2">
              <btn
                icon="close"
                color="white"
                iconSize="medium"
                @clickClose="clickClose"
                emitName="clickClose"
              />
            </div>
            <div
              v-if="
                !state.showStats && !state.showPollDetail && !state.showAbout
              "
              class="mr-2"
            >
              <btn
                icon="bar-chart"
                color="white"
                iconSize="medium"
                @showStats="showStats"
                emitName="showStats"
              />
            </div>
          </div>
        </div>
      </div>
      <transition>
        <div
          v-if="state.showCookieCheck"
          class="
            fixed
            inset-x-0
            bottom-0
            sm:h-60
            h-80
            w-full
            bg-white
            shadow-xl
            border-t border-gray-500
          "
        >
          <div class="p-4">
            <div class="">
              <div class="text-lg font-bold text-theme pb-2">Cookies</div>
              <div class="text-xs text-gray-700">
                We track what you've voted for to prevent multiple votes from
                the same device, and so that you can see the results of polls
                you've voted in. We don't use any third party cookies, or share
                your information with any third parties for marketing purposes
                or otherwise.<br /><br />
                <span class="font-bold text-theme">
                  Only continue using this site if you're ok with that.</span
                >
              </div>
              <btn
                class="mt-4"
                @confirmCookies="confirmCookies"
                emitName="confirmCookies"
                buttonText="I'm ok with that"
                bold
                color="theme"
                fill
                large
                :ripple="false"
              />
            </div>
          </div>
        </div>
      </transition>
      <!-- <div class="" v-if="!state.showAbout && !state.showPollDetail">
        <div class="fixed bottom-0 right-0">
          <btn
            v-if="!state.showStats"
            class="mr-4 mb-4"
            :fill="true"
            icon="bar-chart"
            color="theme"
            iconSize="large"
            @showStats="showStats"
            emitName="showStats"
          />
          <btn
            v-else
            class="mr-4 mb-4"
            :fill="true"
            icon="close"
            color="theme"
            iconSize="large"
            @clickClose="clickClose"
            emitName="clickClose"
          />
        </div>
      </div> -->

      <!-- <div
        v-if="!state.showAbout && !state.showPollDetail && !state.showStats"
        class="z-100 shadow fixed top-12 left-0 right-0 h-12 bg-white"
      >
        <div class="flex items-center h-12 justify-between max-w-sm mx-auto">
          <div
            class="
              w-1/2
              h-full
              text-center text-xs
              uppercase
              font-semibold
              cursor-pointer
              text-theme
              border-b-2 border-theme
              flex
              items-center
              justify-center
            "
          >
            Older polls
          </div>
          <div
            class="
              w-1/2
              h-full
              text-center text-xs
              uppercase
              font-semibold
              cursor-pointer
              text-gray-400
              border-b-2 border-white
              flex
              items-center
              justify-center
            "
            @click="$router.push('/mypolls')"
          >
            Today's polls
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  db,
  uid,
  livePolls,
  fetchPolls,
  getShowResult,
  deletedPolls,
  navLog,
} from "../db/firebase";
import {
  getPollFeedIds,
  pollFeedIds,
  pollThreads,
  setPlayMode,
} from "../db/feed";
import {
  playMode,
  initPlayMode,
  endPlayMode,
  resumePlayMode,
  pausePlayMode,
} from "../db/play";
import { doc, onSnapshot } from "firebase/firestore";
import { reactive, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Loading from "../components/ui/loading.vue";
import Splash from "../components/ui/splash.vue";
import PollItem from "../components/poll/poll-item/poll-item.vue";
import PollDetail from "../components/poll/poll-detail/poll-detail.vue";
import Btn from "../components/ui/buttons/btn.vue";
import About from "./About.vue";
import Stats from "./Stats.vue";
export default {
  components: {
    Btn,
    Splash,
    PollItem,
    PollDetail,
    // ThreadItem,
    // Modal,
    // // Icon,
    About,
    Stats,
    // Loading
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      showPollDetail: false,
      selectedPoll: null,
      showAbout: false,
      showStats: false,
      pollsLoaded: false,
      showCookieCheck: false,
    });

    const checkCookies = () => {
      if (localStorage.getItem("cookieconsent") == "false") {
        setTimeout(() => {
          state.showCookieCheck = true;
        }, 3000);
      }
    };
    checkCookies();
    const confirmCookies = () => {
      console.log("confirm cookies");
      localStorage.setItem("cookieconsent", true);
      state.showCookieCheck = false;
    };

    const goto = () => {
      router.push("/asdasd");
    };
    const getFeed = () => {
      // console.log("getting feed");
      getPollFeedIds();
    };

    const setPoll = (pollId) => {
      // state.showPollDetail = false;
      onSnapshot(doc(db, "polls-live", pollId), (doc) => {
        state.selectedPoll = doc.data();
        // console.log(doc.data());
        // state.showPollDetail = true;
      });
    };

    const clickClose = () => {
      // console.log("click close");
      // if (playMode.timer == "paused") {
      //   router.back();
      //   resumePlayMode();
      // } else {
      router.push("/");
      state.showStats = false;
      state.showPollDetail = false;
      state.selectedPoll = null;
      endPlayMode();
      // }
    };

    const showPoll = (pollId) => {
      return deletedPolls.deletedPolls.indexOf(pollId) == -1;
    };

    const closeAbout = () => {
      state.showAbout = false;
    };
    const showAbout = () => {
      state.showAbout = true;
    };

    const closeStats = () => {
      if (playMode.timer == "paused") {
        console.log("close from stats");
        router.back();
        resumePlayMode();
      } else {
        router.push("/");
      }
      state.showStats = false;
    };
    const showStats = () => {
      state.showStats = true;
    };

    const showStatsFromPlay = () => {
      state.showStats = true;
      console.log("show stats from play");
      pausePlayMode();
    };

    const logTime = (secs) => {
      navLog(secs + " secs passed, " + pollFeedIds.ids.length);
    };

    watch(
      () => route.params,
      (prev, next) => {
        // console.log(route.params);
        if (route.params.pollId) {
          if (route.params.pollId == "stats") {
            state.showStats = true;
          } else {
            navLog("loaded poll: " + route.params.pollId);
            // setPoll(route.params.pollId);
            // console.log("HOME watch: ", route.params.pollId);
            if (getShowResult(route.params.pollId)) {
              // console.log("ansswerd poll");
            } else {
              if (!playMode.transition) {
                initPlayMode(route.params.pollId);
              }
            }
            state.showPollDetail = true;
          }
        } else {
          state.showPollDetail = false;
        }
      }
      // { immediate: true }
    );
    onMounted(async () => {
      getFeed();
      fetchPolls();
      navLog("home page mounted");
      setTimeout(() => {
        logTime("1");
      }, 1000);
      setTimeout(() => {
        logTime("5");
      }, 5000);
      setTimeout(() => {
        logTime("10");
      }, 10000);
      if (route.params.pollId) {
        setPoll(route.params.pollId);
        state.showPollDetail = true;
        if (!getShowResult(route.params.pollId)) {
          initPlayMode(route.params.pollId);
        }
      }
    });

    const dummyClass = () => {
      // if (!state.pollsLoaded) {
      //   navLog("polls fetched");
      // }
    };

    return {
      state,
      goto,
      showPoll,
      pollFeedIds,
      setPoll,
      clickClose,
      closeAbout,
      playMode,
      showAbout,
      closeStats,
      showStats,
      showStatsFromPlay,
      dummyClass,
      confirmCookies,
    };
  },
};
</script>

<style>
</style>