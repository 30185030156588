<template>
  <div class="flex justify-end min-w-full pb-2 px-6 -mt-4">
    <!-- <div class="flex">
      <item-info class="font" :info="getVoteString()" />
      <item-info class="mx-2" info="&#8226;" />
      <item-info class="font" :info="getVoteStatusString()" />
    </div> -->
    <router-link :to="'/' + pollId">
      <div
        class="flex text-theme p-2 hover:bg-gray-50 cursor-pointer rounded-full"
      >
        <icon name="comment" color="theme" />
        <div class="text-xs ml-1 mt-1">{{ commentCount }}</div>
        <!-- <btn
        icon="share"
        class="ml-2"
        @openDetail="openDetail"
        emitName="openDetail"
      /> -->
      </div>
    </router-link>
  </div>
</template>

<script>
import ItemTitle from "../poll-item/item-title.vue";
import ItemInfo from "../poll-item/item-info.vue";
import { getDateString, getVoteStatus } from "../../../db/utils";
import Icon from "../../ui/icons/icon.vue";
import Btn from "../../ui/buttons/btn.vue";
import { getShowResult } from "../../../db/firebase";
import { reactive, ref } from "vue";
export default {
  props: [
    "status",
    "timeLimit",
    "deadline",
    "totalVotes",
    "commentCount",
    "pollId",
  ],
  components: { Icon },
  methods: {
    getVoteString() {
      return this.totalVotes + " votes";
    },

    getVoteStatusString() {
      if (this.status != "live") {
        return "<not live>";
      }
      return getVoteStatus(this.timeLimit, this.deadline);
    },
    openDetail() {
      console.log("goto comments");
      // this.$router.push("/" + this.pollId);
      // this.$router.push({
      //   name: "PollFeedDetail",
      //   params: { pollId: this.pollId },
      // });
    },
  },
};
</script>

<style>
</style>