<template>
  <div class="text-xs text-gray-400">{{ info }}</div>
</template>

<script>
export default {
  props: ["info"],
};
</script>

<style>
</style>