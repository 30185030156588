<template>
  <div class="w-full h-full fixed top-0 left-0" @click="$emit('closeModal')">
    <div
      class="
        w-full
        h-full
        bg-black bg-opacity-50
        flex
        items-center
        justify-center
      "
    >
      <div
        class="
          bg-white
          w-full
          h-full
          sm:w-11/12 sm:h-5/6 sm:max-w-md
          md:rounded
          shadow-lg
          z-50
          relative
          flex flex-col
        "
        @click.stop
      >
        <Suspense>
          <router-view :poll="poll" :newPoll="newPoll"></router-view>
        </Suspense>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["poll", "newPoll"],
};
</script>

<style>
</style>