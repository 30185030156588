<template>
  <div class="text-xs mb-4" v-if="showThreadLink()">
    This poll is part of a thread:
    <span
      class="text-default underline font-semibold cursor-pointer"
      @click="gotoThread()"
      >{{ state.threadName }}</span
    >
  </div>
</template>

<script>
import { pollThreads } from "../../../db/feed";
import { useRouter, useRoute } from "vue-router";
import { reactive } from "vue";
// import
export default {
  props: ["threadId"],
  // components: { ResultBar, PollItemHeader, PollItemFooter, threadIdLink },

  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      threadName: false,
      threadCount: 0,
    });

    const setThread = () => {
      pollThreads.pollThreads.forEach((thread, index) => {
        if (props.threadId == thread.threadId) {
          state.threadName = thread.name;
          state.threadCount = thread.count;
        }
      });
      return;
    };
    setThread();

    const showThreadLink = () => {
      return (
        (route.name == "Feed" || route.name == "PollFeedDetail") &&
        state.threadCount > 1
      );
    };

    const gotoThread = () => {
      router.push("/thread/" + props.threadId);
    };

    return {
      pollThreads,
      showThreadLink,
      gotoThread,
      state,
    };
  },
};
</script>

<style>
</style>