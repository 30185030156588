<template>
  <div class="w-full relative" :class="img ? 'mb-10' : ''">
    <div
      class="
        w-16
        h-16
        rounded-full
        bg-gray-300
        overflow-hidden
        absolute
        left-1
        -top-1
      "
      v-if="img"
    >
      <img class="w-16 h-16 object-cover" :src="img" />
    </div>
    <div :class="img ? 'ml-16' : ''">
      <div class="flex justify-between mb-1 mt-2" :class="getTextClasss()">
        <div v-if="!reversed" class="text-xs pt-1">{{ text }}</div>
        <div class="text-xs pt-1">{{ getWidthStr() }}</div>
        <div v-if="reversed" class="text-xs pt-1">{{ text }}</div>
      </div>
      <div
        class="shadow w-full bg-gray-200 flex"
        :class="{ reversed: reversed }"
      >
        <div
          :style="getWidthStyle"
          class="
            text-xs
            leading-none
            py-1
            text-center text-white
            transition-width transition-slowest
            ease
            w-0
            h-3
          "
          :class="getBarClass()"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getColor } from "../../../db/utils";
export default {
  props: [
    "text",
    "count",
    "totalVotes",
    "isTopResult",
    "type",
    "id",
    "reversed",
    "img",
  ],
  computed: {
    getWidthStyle: function () {
      const obj = { width: `${this.getWidthStr()}` };
      return obj;
    },
  },
  methods: {
    getWidthStr() {
      const pct = Math.round((this.count / this.totalVotes) * 1000) / 10;
      return `${pct}%`;
    },
    getBarClass() {
      let isFaded = false;
      if (!this.isTopResult) {
        isFaded = true;
      }
      return getColor("bg", this.type, isFaded);
    },
    getTextClasss() {
      let str = [];
      if (this.img) {
        str.push("ml-2");
      }
      if (this.isTopResult) {
        str.push("font-semibold");
        return str.join(" ");
      } else {
        str.push("text-gray-500");
        return str.join(" ");
      }
    },
  },
};
</script>

<style>
.reversed {
  /* transform: rotate(180deg); */
  justify-content: end;
}
</style>