<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="getClass()"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      :d="getIcon()"
    />
  </svg>
</template>

<script>
export default {
  props: {
    large: Boolean,
    small: Boolean,
    xsmall: Boolean,
    medium: Boolean,
    name: String,
  },
  methods: {
    getClass() {
      if (this.xsmall) {
        return "h-2 w-2";
      }
      if (this.small) {
        return "h-4 w-4";
      }
      if (this.medium) {
        return "h-6 w-6";
      }
      if (this.large) {
        return "h-8 w-8";
      }
      return "h-5 w-5";
    },
    getIcon() {
      if (this.name == "close") {
        return "M6 18L18 6M6 6l12 12";
      }
      if (this.name == "chevron-down") {
        return "M19 9l-7 7-7-7";
      }
      if (this.name == "check") {
        return "M5 13l4 4L19 7";
      }
      if (this.name == "trash") {
        return "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16";
      }
      if (this.name == "thumb-up") {
        return "M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5";
      }
      if (this.name == "thumb-down") {
        return "M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5";
      }
      if (this.name == "check") {
        return "M5 13l4 4L19 7";
      }
      if (this.name == "photograph") {
        return "M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z";
      }
      if (this.name == "comment") {
        return "M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z";
      }
      if (this.name == "share") {
        return "M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z";
      }
      if (this.name == "info") {
        return "M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z";
      }
      if (this.name == "menu") {
        return "M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5";
      }
      if (this.name == "play") {
        return "M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z";
      }
      if (this.name == "pause") {
        return "M15.75 5.25v13.5m-7.5-13.5v13.5";
      }
      if (this.name == "stop") {
        return "M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z";
      }
      if (this.name == "bar-chart") {
        return "M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z";
      }
      if (this.name == "chevron-right") {
        return "M8.25 4.5l7.5 7.5-7.5 7.5";
      }
      if (this.name == "chevron-left") {
        return "M15.75 19.5L8.25 12l7.5-7.5";
      }
      if (this.name == "pause-circle") {
        return "M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z";
      }
      if (this.name == "play-circle") {
        return "M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z";
      }
      if (this.name == "stop-circle") {
        return "M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z";
      }
    },
  },
};
</script>

<style>
</style>

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
</svg>
