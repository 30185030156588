<template>
  <div
    class="
      flex
      justify-between
      px-1
      pt-2
      pb-3
      relative
      border-b border-gray-200
    "
  >
    <div class="flex">
      <div v-if="img" class="mt-2 ml-3">
        <img-thumb :imgurl="img" />
      </div>
      <div class="ml-2 mt-2">
        <div class="flex">
          <div
            v-if="status == 'live'"
            class="text-xs text-gray-400 font-semi-bold"
          >
            {{ dateString() }}
          </div>
          <div v-else class="text-xs text-gray-400 font-semi-bold">
            [just now]
          </div>
        </div>
        <div class="font-bold">{{ title }}</div>
        <div class="flex mt-1">
          <item-info class="font" :info="getVoteString()" />
          <!-- <item-info class="mx-2" info="&#8226;" />
          <item-info class="" info="no deadline" /> -->
        </div>
      </div>
    </div>
    <div class="ml-4 mr-1 hidden">
      <btn
        icon="close"
        color="buttongrey"
        @closeModal="clickClose"
        emitName="closeModal"
      />
    </div>
  </div>
</template>

<script>
import ImgAvatar from "../../ui/img/img-avatar.vue";
import ImgThumb from "../../ui/img/img-thumb.vue";
import BtnClose from "../../ui/buttons/btn-close.vue";
import Btn from "../../ui/buttons/btn.vue";
import { getDateString } from "../../../db/utils";
import ItemInfo from "../poll-item/item-info.vue";
export default {
  props: ["title", "img", "date", "votes", "status"],
  components: {
    // BtnClose,
    Btn,
    ItemInfo,
    ImgThumb,
  },
  methods: {
    clickClose() {
      console.log("close modal button");
      this.$router.push("/");
    },
    dateString() {
      if (this.date) {
        return getDateString(this.date);
      } else {
        return "no date";
      }
      // console.log("detail header date");
      // console.log(this.date);
    },
    getVoteString() {
      const suffix = this.votes == 1 ? " vote" : " votes";
      return this.votes + suffix;
    },
  },
};
</script>

<style>
</style>