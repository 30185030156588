<template>
  <div class="about absolute bg-gray-white overflow-y-auto min-h-screen">
    <div class="px-4 bg-white pb-12">
      <div class="mt- pt-4 max-w-sm mx-auto text-sm">
        <div class="" v-if="countCheck()">
          <div class="">
            This is how you have voted after
            <span class="font-medium"
              >{{ answeredPolls.answeredPolls.length }} polls</span
            >:
          </div>
          <div class="mt-8 flex">
            <result-column
              text="With the minority"
              :count="state.minority"
              :totalVotes="state.totalVotes"
              :isTopResult="true"
              type="negative"
            />
            <result-column
              text="In between"
              :count="state.medium"
              :totalVotes="state.totalVotes"
              :isTopResult="true"
              type="amber"
            />
            <result-column
              text="With the majority"
              :count="state.majority"
              :totalVotes="state.totalVotes"
              :isTopResult="true"
              type="positive"
            />
          </div>
        </div>
        <div
          class="
            pt-4
            mx-auto
            text-xl text-center text-gray-400
            font-light
            leading-8
          "
          v-else
        >
          Your stats will show here after answering 5 or more public polls
        </div>
      </div>
      <div class="flex justify-center my-8" v-if="showContinue">
        <btn
          :fill="true"
          color="theme"
          buttonText="Continue"
          @continueFromStatsClick="continueFromStatsClick"
          emitName="continueFromStatsClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { answeredPolls, pollResponses, db } from "../db/firebase";
import { doc, getDoc } from "firebase/firestore";
import { reactive } from "vue";
import ResultColumn from "../components/poll/results/result-column.vue";
import Btn from "../components/ui/buttons/btn.vue";
import { continueFromStats } from "../db/play";
export default {
  components: {
    ResultColumn,
    Btn,
  },
  props: ["showContinue"],
  setup() {
    const state = reactive({
      totalVotes: 0,
      majority: 0,
      medium: 0,
      minority: 0,
    });
    const THRESHOLD = 5;
    const countCheck = () => {
      // const responses = pollResponses.pollResponses;
      // responses.forEach((item) => {
      //   console.log(item);
      // });
      if (answeredPolls.answeredPolls.length >= THRESHOLD) {
        return true;
      } else {
        return false;
      }
    };
    const getResults = async () => {
      pollResponses.pollResponses.forEach(async (item) => {
        const docRef = doc(db, "polls-live", item.pollId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          let max = data["count_0"];
          let min = data["count_0"];
          let maxIndex = 0;
          let minIndex = 0;
          for (let i = 1; i < 10; i++) {
            if (data["count_" + i] > max) {
              max = data["count_" + i];
              maxIndex = i;
            }
            if (data["count_" + i] < min && data["count_" + i] > 0) {
              min = data["count_" + i];
              minIndex = i;
            }
          }
          if (item.index == maxIndex) {
            state.majority = state.majority + 1;
          } else if (item.index == minIndex) {
            state.minority = state.minority + 1;
          } else {
            state.medium = state.medium + 1;
          }
          state.totalVotes = state.totalVotes + 1;
        } else {
          console.log("No such document!");
        }
      });
    };
    getResults();
    const continueFromStatsClick = () => {
      continueFromStats();
    };
    return {
      state,
      countCheck,
      answeredPolls,
      continueFromStatsClick,
    };
  },
};
</script>

<style>
</style>