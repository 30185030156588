<template>
  <div class="pt-">
    <div class="text-xs pb-1 font-medium text-gray-600 mb-2">
      Image (optional)
    </div>
    <div class="w-full sm:w-1/2">
      <input-image :index="0" @updateImg="handleUpdateImg" type="score" />
      <!-- <input-base
          placeholdertext="Caption 2 (optional)"
          full
          emitName="updateCaption2"
          @updateCaption2="updateCaption2"
          class="ml-2 -mt-"
        /> -->
    </div>
  </div>
</template>

<script>
// import AddThumbnail from "../ui/inputs/add-thumbnail.vue";
import Btn from "../ui/buttons/btn.vue";
import OptionDefault from "./option-default.vue";
import InputImage from "../ui/inputs/input-image.vue";
// import DropdownMenu from "../components/ui/dropdown/dropdown-menu.vue";
import { updateImg, options } from "../../db/new-poll/score";

export default {
  components: {
    // InputBase,
    // Btn,
    // OptionDefault,
    InputImage,
    // DropdownMenu
  },
  setup() {
    const handleUpdateImg = (index, blob) => {
      updateImg(index, blob);
    };

    return {
      options,
      handleUpdateImg,
    };
  },
};
</script>

<style>
</style>