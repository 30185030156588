import { reactive } from '@vue/reactivity';
import { db, uid, uploadImg, votedLog, pollsLive, increment, arrayUnion } from '../firebase';
import {  collection, addDoc } from "firebase/firestore";
import {  uploadPoll as uploadNewPoll } from './new-poll';

// const newPollOptionDefault = {
//   index: 0,
//   type: 'default',
//   text: null,
//   count: 0
// }

let options = [
  {
    "count": 0,
    "index": 0,
    "text": "0",
    "type": "default"
  },
  {
    "count": 1,
    "index": 1,
    "text": "1",
    "type": "default"
  },
  {
    "count": 2,
    "index": 2,
    "text": "2",
    "type": "default"
  },
  {
    "count": 3,
    "index": 3,
    "text": "3",
    "type": "default"
  },
  {
    "count": 4,
    "index": 4,
    "text": "4",
    "type": "default"
  },
  {
    "count": 5,
    "index": 5,
    "text": "5",
    "type": "default"
  }
]

let scorePoll = reactive({
  options: options,
  blob: null,
  imgURL: null,
  type: "score",
  status: "preview",
})

const updateImg = (imgURL, blob) => {
  scorePoll.imgURL = URL.createObjectURL(blob);
  scorePoll.blob = blob
}


const uploadPoll = async () => {
  // const docRef = getDocRef();
  const docRef = await addDoc(collection(db, "polls-stage"), {});
  if (scorePoll.blob){
    await uploadImg(docRef.id + "_0", scorePoll.blob).then(res => {
      scorePoll.imgURL = res
    })  
  } 
  delete scorePoll['blob'] 
  uploadNewPoll(scorePoll, docRef.id)
}

export {
  scorePoll,
  options,
  updateImg,
  uploadPoll,
}