<template>
  <div class="">
    <div v-if="state.loading" class="flex justify-center mt-16">
      <loading />
    </div>
    <div v-else class="">
      <div class="h-full">
        <div v-if="!showResult" class="flex">
          <!-- <div class="w-1/2 text-xs font-semibold text-center mb-1">
            <div class="">{{ poll.options[0].text }}</div>
          </div>
          <div class="w-1/2 text-xs font-semibold text-center mb-1">
            {{ poll.options[1].text }}
          </div> -->
          <!-- <div class="my-4"></div> -->
        </div>
        <div v-else class="flex mb-2">
          <result-bar
            :key="0"
            :count="getResultCount(0)"
            :totalVotes="state.pollObj.totalVotes"
            :isTopResult="poll.options[0].index == getTopResult()"
            :type="poll.options[0].type"
            :reversed="true"
            class="mr-1"
          />
          <result-bar
            :key="1"
            :count="getResultCount(1)"
            :totalVotes="state.pollObj.totalVotes"
            :isTopResult="poll.options[1].index == getTopResult()"
            :type="poll.options[1].type"
            class="ml-1"
          />
        </div>
        <div class="flex h-full">
          <div
            :disabled="showResult"
            class="
              w-1/2
              mr-1
              p-4
              flex
              justify-center
              items-center
              min-h-80
              cursor-pointer
            "
            :class="getBackgroundClass(0)"
            @click="selectOption(0)"
          >
            <div
              class="text-center text-3xl font-bold uppercase select-none"
              :class="getTextClass(0)"
            >
              {{ poll.options[0].text }}
            </div>
          </div>
          <div
            :disabled="showResult"
            class="
              w-1/2
              ml-1
              p-4
              flex
              justify-center
              items-center
              cursor-pointer
            "
            :class="getBackgroundClass(1)"
            @click="selectOption(1)"
          >
            <div
              class="text-center text-3xl font-bold uppercase select-none"
              :class="getTextClass(1)"
            >
              {{ poll.options[1].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import { uploadPoll } from "../../../db/new-poll/binary-text";
import { db, submitVote } from "../../../db/firebase";
import Loading from "../../ui/loading.vue";
import ResultBar from "../results/result-bar.vue";
import { doc, onSnapshot } from "firebase/firestore";
import { getColor } from "../../../db/utils";
import { bg } from "date-fns/locale";
export default {
  props: ["poll", "showResult", "pollId"],
  components: {
    Loading,
    ResultBar,
  },
  setup(props) {
    const state = reactive({
      loading: false,
      pollObj: props.poll,
      pollId: props.pollId,
      showResult: props.showResult,
    });
    const route = useRoute();
    let pollId;
    if (state.pollId) {
      pollId = state.pollId;
      const unsub = onSnapshot(doc(db, "polls-live", pollId), (doc) => {
        state.pollObj = doc.data();
      });
    } else if (route.name == "PollFeedDetail") {
      pollId = route.params.pollId;
      const unsub = onSnapshot(
        doc(db, "polls-live", route.params.pollId),
        (doc) => {
          state.pollObj = doc.data();
        }
      );
    }

    const selectOption = async (option) => {
      if (props.showResult) {
        return;
      }
      state.loading = true;
      // console.log("select option: ", option);
      submitVote(pollId, option).then(() => {
        // console.log("submitted");
      });
    };
    const getResultCount = (index) => {
      return state.pollObj["count_" + index];
    };

    const getTopResult = () => {
      let indexTop = 0;
      let countTop = state.pollObj["count_0"];
      state.pollObj.options.forEach((item, index) => {
        if (state.pollObj["count_" + item.index] > countTop) {
          indexTop = item.index;
          countTop = state.pollObj["count_" + item.index];
        }
      });
      return indexTop;
    };

    const getTextClass = (index) => {
      const top = getTopResult();
      let color = "text-" + state.pollObj.options[index].type;
      if (top != index && props.showResult) {
        color = color + "faded";
      }

      return "text-white";
      // return color;
    };

    const getBackgroundClass = (index) => {
      const top = getTopResult();
      let bgClass;
      // if (top != index && props.showResult) {
      //   bgClass = getColor("text", state.pollObj.options[index].type, true);
      // } else {
      //   bgClass = getColor("text", state.pollObj.options[index].type);
      // }
      if (!state.showResult) {
        bgClass = bgClass + " button-ripple-unrounded ";
      }
      // return bgClass;
      bgClass =
        bgClass + "cursor-pointer bg-" + state.pollObj.options[index].type;
      if (top != index && state.showResult) {
        bgClass = bgClass + "faded ";
      }
      // bgClass = bgClass + " hover:bg-themefaded";
      if (!state.showResult) {
        bgClass =
          bgClass + " hover:bg-" + state.pollObj.options[index].type + "faded";
      }
      return bgClass;
    };

    return {
      state,
      selectOption,
      getResultCount,
      getTopResult,
      getTextClass,
      getBackgroundClass,
    };
  },
  methods: {
    publishPoll() {
      console.log("publish");
      uploadPoll();
    },
    editPoll() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>