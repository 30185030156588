import { createRouter, createWebHistory } from 'vue-router'
import { auth, fb } from "../db/firebase"
import Home from '../views/Home.vue'
import CreatePoll from '../views/CreatePoll.vue'
import PollDetail from '../components/poll/poll-detail/poll-detail.vue'
import PollPreview from '../components/poll/poll-detail/poll-preview.vue'
import Login from "../views/Login.vue"
import MyPolls from "../views/MyPolls.vue"
import { nextDay } from 'date-fns'

const routes = [
  {
    path: '/',
    name: 'Feed',
    component: Home,
    children: [{
      path: ':pollId',
      name: 'PollFeedDetail',
      component: PollDetail,
      props: true,
    }]       
  },
  {
    path: '/mypolls',
    name: 'MyPolls',
    component: MyPolls,
    children: [{
      path: ':pollId',
      name: 'MyPollDetail',
      // component: MyPollDetail,
      props: true,
    }]       
  },
  {
    path: '/thread',
    name: 'Threads',
    component: Home,
    children: [{
      path: ':threadId',
      name: 'ThreadSelected',
      component: Home,
      props: true,  
    }]       
  },
  {
    path: '/threaditem',
    name: 'ThreadList',
    component: Home,
    children: [{
      path: ':pollId',
      name: 'ThreadDetail',
      component: PollDetail,
      props: true,
    }]       
  },
  {
    path: '/wip',
    name: 'Wip',
    component: () => import(/* webpackChunkName: "about" */ '../views/Wip.vue')
  },
  {
    path: '/layout',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeLayout.vue')
  },
  {
    path: '/stage',
    name: 'StageFeed',
    component: () => import(/* webpackChunkName: "about" */ '../views/Stage.vue'),
    meta: {
      requiresAuth: true
    },  
    children: [{
      path: ':pollId',
      name: 'StageDetail',
      component: PollDetail,
      props: true
    }]    
  },
  {
    path: '/createpoll',
    name: 'CreatePoll',
    component: CreatePoll,
    meta: {
      requiresAuth: true
    },    
    children: [{
      path: 'preview',
      name: 'Preview',
      component: PollPreview,
      props: true

    }]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const adminIds = ['NvfI0W2XAaWr3JSwhjJDKFp36dw2']


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const checkId = (id) => {
    return adminIds.includes(id)
  }
  if (requiresAuth){
    fb.auth().onAuthStateChanged((user) => {
      if (user) {
        let uid
        uid = user.uid
        if (checkId(uid)){
          next()
        } else {
          next("/")
        }
      }
    });
  } 

  else next()
})

export default router
