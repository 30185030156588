<template>
  <div class="min-w-sm max-w-sm mx-auto max-h-screen min-h-screen relative">
    <div
      v-if="state.showAbout"
      class="fixed top-12 left-0 right-0 bottom-0 bg-white"
    >
      <div class="max-w-sm min-w-sm mx-auto">
        <transition>
          <about
            v-if="state.showAbout"
            class="fixed inset-0"
            @closeAbout="closeAbout"
          />
        </transition>
      </div>
    </div>

    <div
      v-if="state.showStats"
      class="fixed top-12 left-0 right-0 bottom-0 bg-white"
    >
      <div class="max-w-sm min-w-sm mx-auto">
        <transition>
          <stats
            v-if="state.showStats"
            class="fixed inset-0"
            @closeStats="closeStats"
          />
        </transition>
      </div>
    </div>

    <!-- HEADER -->
    <div class="z-100 shadow fixed top-0 left-0 right-0 h-12">
      <div class="bg-theme">
        <div class="flex items-center h-12 justify-between max-w-sm mx-auto">
          <div class="flex items-center justify-between ml-2 sm:ml-0">
            <div class="flex items-center cursor-pointer mr-4">
              <img
                class="h-8 rounded-full"
                src="@/assets/op_icon.png"
                alt=""
                @click="showAbout()"
              />
              <img
                class="h-8 rounded-full ml-2"
                src="@/assets/yopolly.png"
                alt=""
                @click="showAbout()"
              />
            </div>
          </div>
          <div v-if="state.showPollDetail && !state.showStats" class="mr-2">
            <btn
              icon="close"
              color="white"
              iconSize="medium"
              @clickClose="clickClose"
              emitName="clickClose"
            />
          </div>
          <div v-if="state.showAbout" class="mr-2">
            <btn
              icon="close"
              color="white"
              iconSize="medium"
              @clickClose="closeAbout"
              emitName="clickClose"
            />
          </div>
          <div v-if="state.showStats" class="mr-2">
            <btn
              icon="close"
              color="white"
              iconSize="medium"
              @clickClose="clickClose"
              emitName="clickClose"
            />
          </div>
          <div
            v-if="!state.showStats && !state.showPollDetail && !state.showAbout"
            class="mr-2"
          >
            <btn
              icon="bar-chart"
              color="white"
              iconSize="medium"
              @showStats="showStats"
              emitName="showStats"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!state.showAbout && !state.showPollDetail && !state.showStats"
        class="z-100 shadow fixed top-12 left-0 right-0 h-12 bg-white"
      >
        <div class="flex items-center h-12 justify-between max-w-sm mx-auto">
          <div
            class="
              w-1/2
              h-full
              text-center text-xs
              uppercase
              font-semibold
              cursor-pointer
              text-gray-400
              border-b-2 border-white
              flex
              items-center
              justify-center
            "
            @click="$router.push('/')"
          >
            public polls
          </div>
          <div
            class="
              w-1/2
              h-full
              text-center text-xs
              uppercase
              font-semibold
              cursor-pointer
              text-theme
              border-b-2 border-theme
              flex
              items-center
              justify-center
            "
          >
            my polls
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "../db/firebase";
import {} from "../db/feed";
import { doc, onSnapshot } from "firebase/firestore";
import { reactive, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Loading from "../components/ui/loading.vue";
import Splash from "../components/ui/splash.vue";
import Btn from "../components/ui/buttons/btn.vue";
import About from "./About.vue";
import Stats from "./Stats.vue";
export default {
  components: {
    Btn,
    // Splash,
    About,
    Stats,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      showPollDetail: false,
      selectedPoll: null,
      showAbout: false,
      showStats: false,
      pollsLoaded: false,
    });

    const showStats = () => {};

    watch(
      () => route.params,
      (prev, next) => {}
    );
    onMounted(async () => {});

    return { state, showStats };
  },
};
</script>

<style>
</style>