<template>
  <div class="-mt-3 mb-1">
    <btn
      class="ml-2 sm:ml-2"
      @selectPass="selectPass"
      emitName="selectPass"
      iconLabel="Not interested"
      color="buttonpass"
      icon="trash"
      :iconFirst="true"
      :disableddummy="true"
    />
  </div>
</template>

<script>
import Btn from "../../ui/buttons/btn.vue";
export default {
  components: {
    Btn,
  },
};
</script>

<style>
</style>